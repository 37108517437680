<template>
    <div class="v-overflow">

        <carousel>
            <slide>
                Slide 1 Content
            </slide>
            <slide>
                Slide 2 Content
            </slide>

            <slide>
                Slide 3 Content
            </slide>

            <slide>
                Slide 4 Content
            </slide>

            <slide>
                Slide 5 Content
            </slide>



        </carousel>


    </div>
</template>
  
  
<style scoped lang="css">
@import "./../assets/annunci/bootstrap-datepicker3.min.css";
@import "./../assets/annunci/bootstrap.min.css";
@import "./../assets/annunci/main.css";
/* @import "./../assets/annunci/owl.carousel.css"; */
@import "./../assets/annunci/select2-bootstrap.min.css";
@import "./../assets/annunci/select2.min.css";
/* @import "./../assets/annunci/settings.css"; */
/* @import "./../assets/annunci/slick-theme.css"; */
/* @import "./../assets/annunci/slick-theme.min.css"; */
@import "./../assets/annunci/slick.css";
@import "./../assets/annunci/style-font1.css";
@import "./../assets/annunci/style-font2.css";
@import "./../assets/annunci/font-awesome.css";
</style>
  
<script src="https://cdn.jsdelivr.net/npm/flickity@2.2.1/dist/flickity.pkgd.min.js"></script>


<script>
//@import "@/assets/loginstart/style.css"
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

//import RiseLoader from "vue-spinner/src/RiseLoader.vue";

//import router from ".././router";
import apisearchads from "../utils/searchads/apisearchads";

import { Carousel, Slide } from 'vue-carousel';

import $ from 'jquery';

/* import Vue from 'vue' */
//import App from '.././App.vue'

export default {
    // components: {
    //   /* PulseLoader, */
    //   RiseLoader,
    // },

    components: {
        Carousel,
        Slide
    },

    updated() { },


    mounted: function () {





        $(document).ready(function () {

            $('.carousel').flickity();


        });



    },
    name: "Login",

    // data:

    data() {
        return {


            txtSearchKeywords: "",
            txtSearchKeywordsPlaceholder: "",



            txtTermOfUse: "",

            txtPrivacy: "",


            textPoweredBy: "",


            pathLogo: "",

            dialogRegister: true,

            notifications: false,
            sound: true,
            widgets: false,

            showSpinner: false,
            input: {
                username: "",
                password: "",
            },

            nameSearch: "",
            funcAreaSearch: "0",
            regioneSearch: "0",
            comuneSearch: "0",

            funcArea: [],
            regioni: [],
            comuni: [],
            comuniTemp: [],

            // items: [
            //   'Functional Area', 'Cities', 'Industries'
            // ],

            items: [
                { id: "tbFunctionalArea", description: "Functional Area" },
                { id: "tbCities", description: "Cities" },
                { id: "tbIndustries", description: "Industries" }
            ],

            adsFuncArea: [],
            adsCities: [],
            adsIndustries: [],

            tab: null,


        };
    },
    methods: {

        setUrlDomainGlobal: function () {

            let result = "";

            if (window.siteForTest == "") {

                if (location.hostname == "localhost") {
                    result = location.protocol + "//emtool.local/";
                }

                if (location.hostname != "localhost") {

                    result = location.protocol + "//" + location.hostname + "/";

                }

            }
            else {

                result = window.siteForTest;

            }



            window.urlSiteCareers = result;

        },


        btnConfirmTermOfUse: function () {

            this.$modal.hide('popupTermUse');


        },


        getWindowMobileTermOfUse: function () {

            if (window.innerWidth <= 768) {

                return 350;

            }
            else {

                return 616;

            }

        },



        getWindowMobile: function () {

            if (window.innerWidth <= 768) {

                return true;

            }
            else {

                return false;

            }

        },


        getWindowDesktop: function () {

            if (window.innerWidth > 768) {

                return true;

            }
            else {

                return false;

            }

        },


        manageTabs: function () {

            if (window.innerWidth <= 768) {

                $("#vTabs").removeClass("v-tabs--right");
                $("#vTabs").addClass("v-tabs--centered");

            }
            else {

                $("#vTabs").addClass("v-tabs--right");
                $("#vTabs").removeClass("v-tabs--centered");

            }
        },

        getClass() {

            console.log("WIND WID: ", window.innerWidth);

            if (window.innerWidth <= 768) {

                return 'center-active centered'
            }


            else {
                return 'right'
            }

        },

        changeTab: function (v_tab) {

            console.log("TAB:", v_tab);

            if (v_tab == "Functional Area") {


                setTimeout(() => {

                    $("#tbFunctionalArea").removeClass("cl-tab");
                    $("#tbFunctionalArea").addClass("cl-tab-select");

                    $("#tbIndustries").removeClass("cl-tab-select");
                    $("#tbIndustries").addClass("cl-tab");

                    $("#tbCities").removeClass("cl-tab-select");
                    $("#tbCities").addClass("cl-tab");


                }, 200);


            }



            if (v_tab == "Cities") {


                setTimeout(() => {

                    $("#idTabCities").empty();

                    // browse job by cities
                    var v_tot_cities = this.adsCities;

                    for (var x = 0; x < v_tot_cities.length; x++) {

                        // var txt = `<li class="col-md-4 col-sm-6"><a style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" title=""` + v_tot_cities[x].nome + `"">` + v_tot_cities[x].nome + ` <span>(` + v_tot_cities[x].v_tot + `)</span></a></li>`;

                        var txt = `<li class="col-md-4 col-sm-6"><a class='clCities' style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_cities[x].idprovincia + `' title=""` + v_tot_cities[x].nome + `"">` + v_tot_cities[x].nome + ` <span>(` + v_tot_cities[x].v_tot + `)</span></a></li>`;




                        $("#idTabCities").append(txt);


                        $("#tbFunctionalArea").removeClass("cl-tab-select");
                        $("#tbFunctionalArea").addClass("cl-tab");

                        $("#tbIndustries").removeClass("cl-tab-select");
                        $("#tbIndustries").addClass("cl-tab");

                        $("#tbCities").removeClass("cl-tab");
                        $("#tbCities").addClass("cl-tab-select");


                    }


                }, 200);



            }

            if (v_tab == "Industries") {


                setTimeout(() => {

                    $("#idTabIndustries").empty();

                    // browse job by cities
                    var v_tot_industries = this.adsIndustries;

                    console.log("INDUSTRIES: ", v_tot_industries);


                    for (var x = 0; x < v_tot_industries.length; x++) {

                        var txt = `<li class="col-md-4 col-sm-6"><a class='clIndustries' style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_industries[x].idcategory + `' title=""` + v_tot_industries[x].v_nome + `"">` + v_tot_industries[x].v_nome + ` <span>(` + v_tot_industries[x].v_tot + `)</span></a></li>`;




                        $("#idTabIndustries").append(txt);



                        $("#tbFunctionalArea").removeClass("cl-tab-select");
                        $("#tbFunctionalArea").addClass("cl-tab");

                        $("#tbCities").removeClass("cl-tab-select");
                        $("#tbCities").addClass("cl-tab");


                        $("#tbIndustries").removeClass("cl-tab");
                        $("#tbIndustries").addClass("cl-tab-select");


                    }


                }, 200);



            }


        },



        setComuni: function (id_regione) {

            console.log("PROV SEL 2: ", id_regione);

            console.log("COMUNI TMP: ", this.comuniTemp);

            this.comuni = [];

            var Comuni = [];



            if (window.urlSiteCareers != "https://jobmule.eu/") {

                Comuni.push({
                    idcomune: "0",
                    idregione: "0",
                    nome: "Select City"
                });

                for (var i = 0; i < this.comuniTemp.length; i++) {

                    if (parseInt(this.comuniTemp[i].idregione) == parseInt(id_regione)) {

                        Comuni.push({
                            idcomune: this.comuniTemp[i].idcomune,
                            idregione: this.comuniTemp[i].idregione,
                            nome: this.comuniTemp[i].nome
                        });

                    }

                }

                console.log("COMUNI SEL: ", Comuni);

                this.comuni = Comuni;

            }
            else {


                var desc_regione = "";
                for (var j = 0; j < this.regioni.length; j++) {
                    if (parseInt(this.regioni[j].idregione) == parseInt(id_regione)) {

                        desc_regione = this.regioni[j].nome.toUpperCase();

                    }
                }





                Comuni.push({
                    idcomune: "0",
                    idregione: "0",
                    nome: "Select City",
                    nome_reg: ""
                });

                for (var i2 = 0; i2 < this.comuniTemp.length; i2++) {



                    if (this.comuniTemp[i2].nome_reg.toUpperCase() == desc_regione) {

                        Comuni.push({
                            idcomune: this.comuniTemp[i2].idcomune,
                            idregione: this.comuniTemp[i2].idregione,
                            nome: this.comuniTemp[i2].nome,
                            nome_reg: this.comuniTemp[i2].nome_reg
                        });



                    }



                }

                console.log("COMUNI SEL: ", Comuni);

                this.comuni = Comuni;

            }







        },

        isEmpty(obj) {
            return Object.keys(obj).length === 0;
        }
        ,
        getCookie() {
            this.$cookies.set("cookie-consent-performance", "yes");

            //  this.$swal('You are logged in!!!');

            //   console.log(Vue.cookie.get('token'));

            // it gets the cookie called `username`
            /* const username = this.$cookies.get("username");
            console.log(username); */
        },


        getValuesSearch: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisearchads.getValuesSearch(
                window.urlSiteCareers
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getValuesSearch", res);

                that.funcArea = res.data.funcArea;
                that.regioni = res.data.regioni;
                that.comuniTemp = res.data.comuni;

                that.funcAreaSearch = "0";
                that.regioneSearch = "0";

                that.pathLogo = res.data.file_logo;


                that.txtPrivacy = res.data.txt_privacy;

                that.txtTermOfUse = res.data.txt_term_of_use;

                // txtTermOfUse


                window.$cookies.set("url_logo_conf", res.data.file_logo, "9y");


                that.adsCities = res.data.query_tot_cities;

                that.adsIndustries = res.data.query_tot_industries;

                // browse job by functional area
                var v_tot_func_area = res.data.query_tot_func_area;

                for (var x = 0; x < v_tot_func_area.length; x++) {

                    var txt = `<li class="col-md-4 col-sm-6"><a class='clFuncArea' style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_func_area[x].idsubcategory + `' title=""` + v_tot_func_area[x].description + `"">` + v_tot_func_area[x].description + ` <span>(` + v_tot_func_area[x].v_tot + `)</span></a></li>`;

                    $("#idTabFyncArea").append(txt);

                }



                // quick links jobs by functional area
                for (var x2 = 0; x2 < v_tot_func_area.length; x2++) {

                    if (x2 <= 2) {

                        var txt2 = `<li><a class='clFuncArea' style="font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_func_area[x2].idsubcategory + `' title=""` + v_tot_func_area[x2].description + `"">` + v_tot_func_area[x2].description + `</a></li>`;

                        $("#jobsByFuncAreaQuickLinks").append(txt2);

                        console.log("ok");


                    }


                }



                // quick links jobs by industries

                var v_tot_industries = that.adsIndustries;

                for (var x3 = 0; x3 < v_tot_industries.length; x3++) {

                    var txt3 = `<li><a class='clIndustries' style="font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_industries[x3].idcategory + `' title=""` + v_tot_industries[x3].v_nome + `"">` + v_tot_industries[x3].v_nome + `</a></li>`;




                    $("#jobsByIndustryQuickLinks").append(txt3);




                }







                $("#tbFunctionalArea").removeClass("cl-tab");
                $("#tbFunctionalArea").addClass("cl-tab-select");

                $(".bttxt").html(res.data.txt_powered_by);
                $(".txtInfoCompany").html(res.data.txt_azi_dx_footer);

                // immagine sfondo header

                $(".searchwrap").css("background", "url('')");


                if (res.data.file_img_sfondo_header != null) {

                    if (res.data.file_img_sfondo_header != "") {

                        var val_css = "url('" + res.data.file_img_sfondo_header + "')";
                        var prop_css = "background";

                        $(".searchwrap").css(prop_css, val_css);

                    }

                }


                // immagine sfondo parallax

                $(".userloginbox").css("background", "url('')");



                if (res.data.file_img_sfondo_parallax != null) {

                    if (res.data.file_img_sfondo_parallax != "") {

                        var val_css2 = "url('" + res.data.file_img_sfondo_parallax + "') no-repeat center";
                        var prop_css2 = "background";

                        $(".userloginbox").css(prop_css2, val_css2);

                        $(".userloginbox").css("background-attachment", "fixed");

                        $(".userloginbox").css("background-size", "cover");

                        $(".userloginbox").css("padding", "40px 0 120px 0");

                        $(".userloginbox").css("text-align", "center");



                    }


                }



                // featured job
                $("#divContFeaturedJobs").html(res.data.html_feat_job);
                $("#divContLatestJobs").html(res.data.html_latest_job);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },

        registerAction: async function () {
            //const auth = { user: this.username, password: this.password };
            // Correct username is 'foo' and password is 'bar'
            //const url = "https://app.emtool.eu/api/emt/register";

            const url = "https://app.emtool.eu/registerUserForCompanyPost";

            //const url = "http://emtool.local/registerUserForCompanyPost";


            this.success = false;
            this.error = null;

            let formData = new FormData();

            formData.append("context", "2recruit");

            if (this.input.username != undefined)
                formData.append("login", this.input.username);

            if (this.input.password != undefined)
                formData.append("password", this.input.password);

            formData.append("companyname", "7");

            if (this.input.name != undefined)
                formData.append("name", this.input.name);

            if (this.input.surname != undefined)
                formData.append("surname", this.input.surname);

            if (this.input.email != undefined)
                formData.append("email", this.input.email);

            if (this.input.phone != undefined)
                formData.append("phone", this.input.phone);

            // if (this.input.namecompany != undefined)
            //   formData.append("namecompany", this.input.namecompany);

            formData.append("app", "2recruit");

            formData.append("is_demo", "S");



            try {
                await this.axios
                    .post(url, formData, { "content-type": "text/json" })
                    .then((result) => {
                        var respo = result.data;
                        this.success = true;
                        // console.log( respo   );

                        setTimeout(() => {
                            this.showSpinner = false;

                            var msgError = "";
                            console.log("re", respo.errors);



                            if (respo.errors && !this.isEmpty(respo.errors)) {
                                console.log("Errori", respo.errors);
                                var err = respo.errors;

                                Object.entries(err).forEach(([key, value]) => {
                                    console.log(`${key} ${value}`);
                                    msgError += `${value}\r\n `;
                                });

                                if (msgError) {
                                    this.$swal({ icon: "error", text: msgError });

                                }


                            }
                            else {
                                this.$swal({
                                    icon: "success",
                                    text: "Please check your email",
                                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_careers.png' />",
                                });

                                this.$router.push({
                                    path: "/",
                                });


                            }
                            //this.$swal("You are logged in!!!");

                            /*     window.$cookies.set("token", respo.token , "9y");
                                window.$cookies.set("system", respo.system,"9y");
                                window.$cookies.set("token_system", respo.token_system,"9y");
                                window.$cookies.set("username", respo.username,"9y");
                                window.$cookies.set("user_id", respo.user_id,"9y");
                                window.$cookies.set("namesurname", respo.namesurname,"9y"); */

                            this.$root.$children[0].curDipTop = respo.namesurname;

                            //router.push({ path: "/" });

                        }, 1);
                    });
            } catch (err) {
                this.success = false;
                console.log(err.message);
                this.$swal({ icon: "error", text: "Please check your data" });

                this.showSpinner = false;
                //  console.log(   response);

                //   this.error = err.message;
            }
        },

        register() {
            this.showSpinner = true;
            this.getCookie();

            this.registerAction();
            /*     if (this.input.username != "" && this.input.password != "") {
              if (
                this.input.username == this.$parent.mockAccount.username &&
                this.input.password == this.$parent.mockAccount.password
              ) {
                this.$emit("authenticated", true);
                this.$router.replace({ name: "secure" });
              } else {
                console.log("The username and / or password is incorrect");
              }
            } else {
              console.log("A username and password must be present");
            } */
        },
    },
};
</script>
  
  
<style>
.clTermOfUse {
    width: 100%;
    overflow-y: auto;
    min-height: 391px;
    max-height: 391px;
    margin-left: 5px;
    margin-top: 5px;
    padding-right: 17px;
}


.clTermOfUse h2 {
    font-family: 'Orbitron', sans-serif !important;
}

.clTermOfUse p {
    font-family: 'Orbitron', sans-serif !important;
}

.clTermOfUse strong {
    font-family: 'Orbitron', sans-serif !important;
    font-weight: bold;
}



.clPipe {
    border: 1px solid rgb(102, 102, 102);
    height: 30px;
    margin-top: 20px;
}

.ads-home .clButtonHeader {
    width: 35px !important;
}

.ads-home .clButtonJobAll {
    width: 31px !important;
    margin-top: 2px !important;
}

.ads-home .clButtonRefresh {
    width: 31px !important;
    margin-top: 2px !important;
}

.searchbarLatestJob .form-control {
    height: 50px;
    padding: 13px 15px;
    border: 1px solid;
    font-size: 18px;
}


.v-application ul,
.v-application ol {
    padding-left: 0px !important;
}


.imgSearchJob {
    margin-top: -2px;
    margin-left: -8px;
    min-width: 269px;
    cursor: pointer;
    box-shadow: 0px 20px 40px 5px rgb(0 0 0 / 20%);
}


.btnSearchJob {
    font-family: Orbitron, sans-serif !important;
    background: '/careers/img/search-job.png' !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin-left: -12px !important;
    width: 276px !important
}




.cl-lj-1 {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 17px !important;
}

.cl-fj-1 {
    font-family: 'Orbitron', sans-serif !important;
}

.cl-fj-2 {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
}

.cl-fj-3 {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
    line-height: 14px !important;
}

.cl-fj-4 {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 15px !important;
}

.cl-fj-5 {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 10px !important;
}

.cl-fj-6 {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    color: #000 !important;
}


.cl-fj-7 {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 8px !important;
}


.cl-fj-8 {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 8px !important;
    font-weight: 400 !important;
    color: #000 !important;
}


.cl-lj-2 {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 13px !important;
}

.cl-portale-1 {
    font-family: 'Orbitron', sans-serif !important;
    color: white !important;
}


.howlist h4 {
    font-family: 'Orbitron', sans-serif !important;
}

.jobslist li .company {
    color: #999;
    padding: 7px 0;
    padding-top: 0px !important;
}

.titleTop h3 {
    font-family: 'Orbitron', sans-serif !important;
}

.titleTop span {
    font-family: 'Orbitron', sans-serif !important;
}

.navbar-nav {
    flex-direction: inherit !important;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
    list-style: none !important;
}


.bttxt {
    color: #888 !important;
    line-height: 24px !important;
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
    display: flex;
    align-items: center;
}

.bttxt a {
    color: #888 !important;
    font-weight: 600 !important;
}

.address {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
}

.email a {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
}

.phone a {
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
}

.footerWrap .email:before {
    position: absolute !important;
    left: 0 !important;
    content: '\f003' !important;
    font-family: 'FontAwesome' !important;
    font-size: 18px !important;
}

ion-select {
    font-size: 18px !important;
    --placeholder-opacity: 1 !important;
    font-family: 'Orbitron', sans-serif !important;
}

.catelist li a:before {
    position: absolute !important;
    left: 0 !important;
    content: '\f0da' !important;
    font-family: 'FontAwesome' !important;
    font-weight: 400 !important;
}

.howlist li .iconcircle {
    text-align: center !important;
    margin-bottom: 15px !important;
    display: inline-block !important;
}

.howlist li .iconcircle i {
    font-size: 48px !important;
    color: #444 !important;
}

.fa {
    display: inline-block !important;
    font: normal normal normal 14px/1 FontAwesome !important;
    font-size: inherit !important;
    text-rendering: auto !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

.fa-user:before {
    content: '\f007' !important;
}

button {
    font-family: 'Orbitron', sans-serif !important;
}

.select-ios .select-placeholder {
    color: #000;
    font-size: 15px;
    font-family: 'Orbitron', sans-serif !important;
}

.idsubcategory {
    min-width: 521px;
}

.idregione {
    min-width: 225px;
}

.idcomune {
    min-width: 225px;
}

.companyid {
    min-width: 223px;
}

.ads-home .cl-tab-select {
    font-size: 16px !important;
    background: #444 !important;
    color: white !important;
    font-family: 'Orbitron', sans-serif !important;
    font-weight: 700 !important;
    padding: 15px 30px !important;
    border-radius: 5px 5px 0 0 !important;
    border-color: #444 !important;
}

.ads-home .newjbox li .jobint {
    box-shadow: none !important;
    border-radius: 0 !important;
    background: #f9fafb !important;
    border-bottom-color: #F2F9FF !important;
    max-height: 180px !important;
}

.ads-home .cl-tab {
    font-size: 16px !important;
    background: none !important;
    color: #666 !important;
    font-family: 'Orbitron', sans-serif !important;
    font-weight: 400 !important;
    padding: 15px 30px !important;
}

.cl-title-jobsby {}

.v-overflow-bt-padding {
    padding-bottom: 6px !important
}

.imgViewDetail {
    width: 40px;
}

.colImgViewDetails {
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.divFeatMobile {
    display: none;
}


.divLatestMobile {
    display: none;
}


@media screen and (max-width: 768px) {

    .searchwrap {
        margin-top: 27px !important;
    }

    .idsubcategory {
        min-width: 278px;
    }

    .idregione {
        min-width: 278px;
    }

    .idcomune {
        min-width: 278px;
    }


    .companyid {
        min-width: 278px;
    }

    .ads-home .cl-tab {
        font-size: 1rem !important;
        background: none !important;
        color: #666 !important;
        padding: 8px !important;
        width: 249px !important;
        min-width: 249px !important;
    }

    .ads-home .cl-tab-select {
        font-size: 1rem !important;
        background: #444 !important;
        color: white !important;
        padding: 8px !important;
        width: 249px !important;
        min-width: 249px !important;
    }

    .cl-title-jobsby {
        height: 108px !important;
    }

    .v-overflow-bt-padding {
        padding-bottom: 40px !important
    }

    .bttxt {
        color: #888 !important;
        line-height: 24px !important;
        font-family: 'Orbitron', sans-serif !important;
        font-size: 14px !important;
        display: block;
        align-items: center;
    }

    .bttxt a {
        color: #888 !important;
        font-weight: 600 !important;
    }


    .btnSearchJob {
        font-family: Orbitron, sans-serif !important;
        background: url('/img/search-job.png') !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        margin-left: -10px !important;
        width: 336px !important
    }

    .imgSearchJob {
        margin-top: -18px;
        margin-left: -1px;
        min-width: 319px;
        cursor: pointer;
        box-shadow: 0px 20px 40px 5px rgb(0 0 0 / 20%);
    }

    .ads-home .clButtonHeader {
        min-width: 22px !important
    }

    .ads-home .clButtonRefresh {
        min-width: 20px !important;
        margin-top: 1px !important;
    }

    .ads-home .newjbox li .jobint {
        box-shadow: none !important;
        border-radius: 0 !important;
        background: #f9fafb !important;
        border-bottom-color: #F2F9FF !important;
        max-height: 184px !important;
    }

    .clPipe {
        border: 1px solid rgb(102, 102, 102);
        height: 17px;
        margin-top: 18px;
    }

    .clTermOfUse {
        width: 100%;
        overflow-y: auto;
        min-height: 391px;
        max-height: 391px;
        margin-left: 5px;
        margin-top: 5px;
        padding-right: 17px;
    }

    .ads-home .clButtonJobAll {
        min-width: 20px !important;
        margin-top: 1px !important;
    }

    .divFeatNoMobile {
        display: none;
    }

    .divFeatMobile {
        display: block;
    }

    .viewAds {
        display: flex;

    }

    .divLatestNoMobile {
        display: none;
    }

    .divLatestMobile {
        display: block;
    }


}
</style>
  
<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
    display: none;
}

#login {
    width: auto;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    margin: auto;
    /*   margin-top: 200px; */
    padding: 20px;
}

#nav,
.v-sheet {
    display: none;
}
</style>
  
  
  