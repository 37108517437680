<template>
    <v-container id="conf_settings_alertmsg" class="w-container editDisease" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
            background-color: #01adff;
            color: white;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="periodo_dal" v-model="malattia.periodo_dal" label="Data Inizio" readonly>
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="periodo_al" v-model="malattia.periodo_al" label="Data Fine" readonly>
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="numero_protocollo" v-model="malattia.numero_protocollo" label="Numero Protocollo">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-textarea id="note" v-model="malattia.note" label="Note"></v-textarea>

                </v-col>

            </v-row>

        </v-form>

    </v-container>
</template>

<script>

//import apisettings from "../utils/settings/apisettings";
import apidisease from "../utils/disease/apidisease";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        console.log("PARAMS: ", this.$route.params.id);

        /*eslint-disable no-undef*/

        this.setupButtons();

        setTimeout(() => {
            this.syncDisease();
        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/diseaseHistory"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },

    data: () => ({

        fieldDis: false,

        showRow: true,
        showRow2: true,

        malattia: {},

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Modifica Malattia",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { is_notify_enable: "N" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

    }),

    methods: {

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/api/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/api/";

            }

            return result + nome_metodo;

        },

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_save_update_32.png",
                    class: "inverted",
                    title: "Salva"

                },

                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back.png",
                    link: "/confOrariChoice",
                    class: "inverted",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro"
                }

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            //var errore = "";

            var that = this;

            console.log("MALATTIA: ", that.malattia);

            that.saveData();



        },

        saveData: function () {

            var v_token = window.$cookies.get("token");

            var startDate = this.malattia.periodo_dal;
            var endDate = this.malattia.periodo_al;
            var certificato = this.malattia.numero_protocollo;
            var notes = this.malattia.note;
            var id_disease = this.$route.params.id;


            console.log("START DATE:", startDate);
            console.log("END DATE:", endDate);
            console.log("CERTIFICATO:", certificato);
            console.log("NOTE:", notes);
            console.log("ID:", id_disease);
            console.log("TOKEN:", v_token);

            var API_URL = this.getUrlDomainApi("updateDisease");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            $.ajax({
                type: "POST",
                url: API_URL,
                data: {
                    "v_user": v_token,
                    'data_inizio': startDate,
                    'data_fine': endDate,
                    'protocollo': certificato,
                    'id_malattia': id_disease,
                    'note': notes

                },
                success: function (resultData) {

                    that.showSpinner = false;
                    that.$root.$children[0].showProgress = false;

                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata salvata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });


                    setTimeout(() => {

                        router.push({
                            path: "/diseaseHistory"
                        });

                    }, 2000);


                },
                error: function (errorData) {
                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;
                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });

                    that.showSpinner = false;
                    that.$root.$children[0].showProgress = false;
                }
            });





            // var that = this;


            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apisettings.saveSettings(
            //     that.impostazioni,
            //     v_token
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from saveSettings", res);

            //     that.$swal({
            //         icon: "success",
            //         text: "Dati salvati correttamente",
            //         showConfirmButton: false,
            //         timer: 2000
            //     });

            //     setTimeout(() => {

            //         that.dialogMsg = false;
            //         that.setupButtons();

            //     }, 200);

            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("ERrori", "Non è stato possibile salvare i dati");
            //     console.log("response", response);

            // }

            // );

        },

        syncDisease: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidisease.getEditDisease(
                v_token,
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getEditDisease", res);

                that.malattia = res.data.disease;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.editDisease .v-input {
    font-size: 1.2em;
}

.editDisease .v-select {
    font-size: 1.2em;
}

.editDisease .v-label {
    font-size: 1em;
}


#conf_settings_alertmsg .v-label {
    font-size: 1em;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
