import axios from 'axios'

class APILoginCandidate {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    async loginAction(email) {


        let formData = new FormData();

        formData.append("email", email);


        return axios.post(this.getUrlDomain("CareersLoginCandidate"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async loginActionJobmule(email, soggetto) {


        let formData = new FormData();

        formData.append("email", email);
        formData.append("soggetto", soggetto);


        return axios.post(this.getUrlDomain("CareersLoginCandidateJobmule"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async loginActionCareers(email, soggetto) {


        let formData = new FormData();

        formData.append("email", email);
        formData.append("soggetto", soggetto);


        return axios.post(this.getUrlDomain("CareersLoginCandidateCareers"), formData, { 'Content-Type': 'multipart/form-data' });

    }


}

export default new APILoginCandidate({
    url: "http://emtool.local/public/api/alertduedate/"
})
