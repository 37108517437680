import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

function getUrlDomainGlobal(nome_metodo)
{

  let result = "";

  if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
  }

  if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

  }

  return result + nome_metodo;


}

var main_domain = getUrlDomainGlobal("");

class APIHolidayPermits {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async getHolPermReq(user)
  {

    let formData = new FormData();

    formData.append("username",  user);
   
    return axios.post(this.getUrlDomain("EmtGetVoicesReqHolidayApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




}

export default new APIHolidayPermits({
  url: main_domain
})
