<template>
    <v-container class="w-container" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
            background-color: rgb(245 207 45);
            color: white;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_login" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Utenti </ion-label>

                            <ion-select id="login" name="login" class='login' cancel-Text="Chiudi" done-Text=""
                                :value="loginUtente" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in utenti" :key="item.login" :value='item.login'> {{
                                        item.name
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

        </v-form>

        <div class="contutenti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetUtenti" class="vutentisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetUtenti" fixed style="position:fixed;">
                                Utenti
                                <v-btn class='closeSheet' icon @click="sheetUtenti = !sheetUtenti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredUtenti" :key="item.login"
                                        @click="manageClickUtenti(item)">
                                        <v-icon aria-hidden="false">mdi-account</v-icon>
                                        <v-list-item-title class='itemUtente'>{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>



    </v-container>
</template>

<script>
import apiassocuserscompany from "../utils/assocuserscompany/apiassocuserscompany";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        var pointerVue = this;


        console.log("PARAMS: ", this.$route.params.id);

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        this.titolo = "Utente Azienda";

        // if (this.$route.params.id > 0) {


        setTimeout(() => {
            this.getUtenti();
        }, 100);

        // }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":
                    //alert("btn_add_tms");
                    this.validate();

                    break;


                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/tipologiapagamento"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_login', function (event) {
                console.log(event);

                pointerVue.sheetUtenti = true;


            });

        });

        pointerVue.hideShowButtons(false);



    },

    computed: {

        filteredUtenti() {

            return _.orderBy(this.utenti.filter(item => {
                if (!this.search) return this.utenti;
                return (item.name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },



    },

    data: () => ({


        loginUtente: null,

        currentFile: null,
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],

        utenti: [],

        currAzienda: "",
        titolo: "Aggiungi Tipologia Pagamento",

        cur_id: 0,

        sheetUtenti: false,
        search: "",

        showPicker: false,
        tipologia_pagamento: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        select: null,
        checkbox: false,
    }),

    methods: {


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {


                    pulsantis = [{
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_ok_32.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        class: "inverted",
                        title: "Conferma"
                    },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        manageClickUtenti: async function (utente) {

            console.log("UTENTE SEL: ", utente);

            this.sheetUtenti = false;

            this.loginUtente = utente.login;

            this.hideShowButtons(true);

        },



        getUtenti: async function () {


            var v_token = window.$cookies.get("token");

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiassocuserscompany.getUtenti(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtenti", res);


                this.utenti = res.data.utenti;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/alertduedate/" + nome_metodo;

        },


        setupButtons: async function () {

            setTimeout(() => {

                // var pulsantis = [{
                //     text: "Salva",
                //     icon: "mdi-content-save",
                //     link: "/timesheet",
                //     id: "btn_save",
                //     disabled: false,
                // },
                // {
                //     text: "Parenti",
                //     icon: "mdi-arrow-left",
                //     link: "/tipologiapagamento",
                //     id: "btn_back",
                //     disabled: false,
                // },

                // ];

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_ok_32.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    class: "inverted",
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("UTENTE: ", that.loginUtente);

            window.$cookies.set("sel_utente_assoc_azi", that.loginUtente, "9y");

            router.push({
                path: "/assocUserCompanyListCmp"
            });

        },

        saveData: async function () {

            console.log("ok");

            // var v_token = window.$cookies.get("token");

            // var that = this;

            // console.log("CUR_FILE: ", that.currentFile);

            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apitipologiepagamento.setTipologiaPagamento(
            //     that.tipologia_pagamento,
            //     that.$route.params.id,
            //     v_token,
            //     that.currentFile

            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from setTipologiaPagamento", res);

            //     that.$swal({
            //         icon: "success",
            //         text: "Dati salvati correttamente",
            //         showConfirmButton: false,
            //         timer: 2000
            //     });

            //     localStorage.removeItem('al_info_global');

            //     $.ajax({
            //         type: "POST",
            //         url: that.getUrlDomain("getSettings") + '?v_user=' + v_token,
            //         success: function (resultData) {

            //             localStorage.setItem('al_info_global', JSON.stringify(resultData));

            //             setTimeout(() => {

            //                 that.dialogMsg = false;
            //                 that.setupButtons();

            //                 router.push({
            //                     path: "/tipologiapagamento"
            //                 });

            //             }, 200);


            //         }
            //     });




            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("ERrori", "Non è stato possibile salvare i dati");
            //     console.log("response", response);

            // }

            // );

        },

        syncTipologiaPagamento: async function () {

            console.log("ok");

            // var v_token = window.$cookies.get("token");

            // var that = this;

            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apitipologiepagamento.getTipologiaPagamento(
            //     that.$route.params.id, v_token

            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from getTipologiaPagamento", res);

            //     try {

            //         this.tipologia_pagamento = res.data.Result;

            //         console.log("IMAGE: ", res.data.Result.image);

            //         var ele_file = res.data.Result.image.split('.');

            //         this.currentFile = new File([ele_file[0]], res.data.Result.image, {
            //             type: "image/png"
            //         });

            //     } catch (error) {
            //         console.log(error);
            //     }

            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("ERrori", "Non è stato possibile caricare i dati");
            //     console.log("response", response);

            // }

            // );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.headerSheetUtenti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemUtente {
    margin-left: 15px;
}


.v-application .text-center {

    overflow: auto;
}


@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
