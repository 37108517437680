import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIAds {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    async initFilter(user, v_prod) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_prod", v_prod);

        return axios.post(this.getUrlDomain("TwoRecruitAdsFiltersApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    // async abilitaAds(id, user) {

    //     let formData = new FormData();

    //     formData.append("username", user);
    //     formData.append("id", id);

    //     return axios.post(this.getUrlDomain("TwoRecruitAdsEnableApi"), formData, { 'Content-Type': 'multipart/form-data' });

    // }

    async abilitaAds(id, id_azi_sel, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_azi_sel", id_azi_sel);

        return axios.post(this.getUrlDomain("TwoRecruitAdsEnableApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async jobMuleInsertAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://jobmule.eu/api/adsinsert", formData, { 'Content-Type': 'multipart/form-data' });

    }

    async jobMuleLCInsertAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://app.lc-service.it/api/adsinsert", formData, { 'Content-Type': 'multipart/form-data' });

    }

    async jobMuleAriallInsertAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://app.ariall.eu/api/adsinsert", formData, { 'Content-Type': 'multipart/form-data' });

    }


    // async disabilitaAds(id, user) {

    //     let formData = new FormData();

    //     formData.append("username", user);
    //     formData.append("id", id);

    //     return axios.post(this.getUrlDomain("TwoRecruitAdsDisableApi"), formData, { 'Content-Type': 'multipart/form-data' });

    // }

    async disabilitaAds(id, id_azi_sel, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_azi_sel", id_azi_sel);

        return axios.post(this.getUrlDomain("TwoRecruitAdsDisableApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async jobMuleRemoveAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://jobmule.eu/api/adsremove", formData, { 'Content-Type': 'multipart/form-data' });

    }

    async jobMuleLCRemoveAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://app.lc-service.it/api/adsremove", formData, { 'Content-Type': 'multipart/form-data' });

    }

    async jobMuleAriallRemoveAds(id) {

        let formData = new FormData();

        formData.append("idads", id);

        return axios.post("https://app.ariall.eu/api/adsremove", formData, { 'Content-Type': 'multipart/form-data' });

    }

    async urgenteAds(id, id_azi_sel, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_azi_sel", id_azi_sel);

        return axios.post(this.getUrlDomain("TwoRecruitAdsUrgentApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async nonUrgenteAds(id, id_azi_sel, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("id_azi_sel", id_azi_sel);

        return axios.post(this.getUrlDomain("TwoRecruitAdsNotUrgentApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteAds(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("TwoRecruitAdsDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async cloneAds(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomain("TwoRecruitAdsCloneApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initInsertAds(user, v_prod) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_prod", v_prod);

        return axios.post(this.getUrlDomain("TwoRecruitAdsInitInsertApiJM"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertAds(ads, user, skills) {

        let formData = new FormData();

        console.log(user);

        formData.append("skills", skills);

        for (var key in ads) {
            formData.append(key, ads[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitAdsInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async initEditAds(id, user, url_site, v_prod) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("url_site", url_site);
        formData.append("v_prod", v_prod);

        return axios.post(this.getUrlDomain("TwoRecruitAdsInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateAds(ads, user, skills, id) {

        let formData = new FormData();

        console.log(user);

        formData.append("skills", skills);
        formData.append("id", id);

        for (var key in ads) {
            formData.append(key, ads[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitAdsUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }





    async checkGroupReceiveCV(idcompany) {

        let formData = new FormData();

        formData.append("idcompany", idcompany);

        return axios.post(this.getUrlDomain("TwoRecruitCheckGroupCVJM"), formData, { 'Content-Type': 'multipart/form-data' });

    }







}

export default new APIAds({
    url: "https://services.ebadge.it/public/api/"
})
