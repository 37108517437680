<template>
  <v-container
    fluid
    fill-height
    class="text-center d-flex align-center justify-center"
    style="max-width: 300px;min-width:300px;"
  >




        <v-form style="min-width:300px;" ref="form" v-model="valid" lazy-validation>

<v-avatar
  color="primary"
  size="56"
>
<span class="white--text text-h5">{{ initials }}</span>

</v-avatar>
        <v-row class="text-center titlenewrow" style="">

         <span  >{{ namesurname }}</span>
       </v-row>



          <v-text-field
            v-model="profile.username"
            label="User"
             readonly
          ></v-text-field>




          <v-text-field
          type="password"
            v-model="oldpassword"
            :rules="genRules"
            label="Old password"
            required
          ></v-text-field>



          <v-text-field
          type="password"
            v-model="password"
            :rules="passwordRules"
            label="New password"
            required
          ></v-text-field>


          <v-text-field
          type="password"
            v-model="rePassword"
            :rules="[passwordConfirmationRule]"
            label="Retype"
            required
          ></v-text-field>










    <div class="padding" style="min-height:100px;" ></div>
        </v-form>
  </v-container>
</template>



<script>
import apiprofile from "../utils/profile/apiprofile";

import { bus } from "../main";
import router from ".././router";



export default {
  destroyed() {
    this.$root.$children[0].bnavbuttons = [];

    bus.$off("bottomIconClickEvent");
    //window.removeEventListener("scroll", this.onScroll, false);

    //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
  },



computed: {
      passwordConfirmationRule() {
      return () => (this.password === this.rePassword) || 'Password must match'
    }

},

  mounted: function () {

/*     let self = this;
    fetch('https://jsonplaceholder.typicode.com/posts')
      .then(response => response.json())
      .then(json => self.$data.items = json) */



    //var res = apiprofile.getProfile(1,2);
setTimeout(() => {
    this.syncProfile ();
  //  var username = window.$cookies.get("username");
  // this.profile.username = username ;


}, 100);
    // console.log (res);


    bus.$on("bottomIconClickEvent", (data) => {
      //this.header = data;
      console.log(data);
      switch (data) {
        case "btn_save_password":
          //alert("btn_add_tms");
          this.validate();

          break;

        case "btn_exit":
          //alert("btn_add_x");
          router.push({ path: "/dash" });
          break;

        default:
          break;
      }
    });

   this.setupButtons();
  },
  data: () => ({

rePassword:"",
oldpassword :"" ,
password :"" ,





passwordRules: [
        (v) => !!v || "Field required",
        (value) => (value && value.length >= 8) || 'minimum 8 characters',
        (value) => (value && /[A-Z]/.test(value)    ) || 'At least one uppercase',

    ],

      showPicker:false,
      profile:{},
    valid: true,
    initials: "",
    namesurname: "",
    iban: "",
    cellulare: "",
    codicefiscale: "",



    codicefiscaleRules: [
      (v) => !!v || "Codice fiscale is required",
      (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
    ],
    cellulareRules: [
      (v) => !!v || "Phone number is required",
      (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
    ],
genRules: [
      (v) => !!v || "Field required",

    ],
    ibanRules: [
      (v) => !!v || "Iban is required",
      (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
  }),

  methods: {

    setupButtons: async function () {


 setTimeout(() => {
      // console.log ("here we go" , this.$root.$children[0].drawer ) ;
      //window.App.$children[0].setToolbar();
      //   this.$root.$children[0].setToolbar();

      var pulsantis = [
        {
          text: "Save",
          icon: "mdi-content-save",
          link: "/timesheet",
          id: "btn_save_password",
          disabled: false,
        },
        // {
        //   text: "Dashboard",
        //   icon: "mdi-home-circle",
        //   link: "/dash",
        //   id: "btn_exit",
        //   disabled: false,
        // },


      ];
      //window.App.$children[0].bnavbuttons = pulsantis;
      this.$root.$children[0].bnavbuttons = pulsantis;

      //   this.$root.$children[0].$refs.btn_add_tms;

      // console.log( "ok" ,  this.$root.$refs.mainApp );
      //this.$root.$refs.mainApp.bnavbuttons = pulsantis;

      //   refs.btn_add_tms
      // console.log ("Refs" , this.$root.$children[0].$refs.btn_add_tms );

      // var refs = this.$root.$children[0].$refs.btn_add_tms ;

      //   var add_tms = refs.btn_add_tms ;

      //d console.log(this.$root.$children[0].$refs.btn_add_tms);
      setTimeout(() => {
        //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
        //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
      }, 100);

      // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
    }, 100);



    },
    savePassword: async function () {

      var username = window.$cookies.get("username");
      var token_system = window.$cookies.get("token_system");
      this.showSpinner = true;
      this.$root.$children[0].showProgress = true ;

      var response;

      console.log ( username, token_system );
      try {


            this.profile.oldpassword = this.oldpassword ;
            this.profile.newpassword = this.password ;


            response = await apiprofile.savePassword(
          username,
          token_system ,
          this.profile
        );
      } catch (error) {
        this.showSpinner = false;
        console.log(error);
        //alert ( error.message);
          this.$swal({ icon: "error", text: "Old password doesn't match" });


      }

        this.showSpinner = false;
        this.$root.$children[0].showProgress = false ;

      try {

          console.log ( response.data.Result );

           this.$swal({ icon: "success", text: "Your data has been saved" });


/*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

        //this.monthArray = response.data.Timesheet;


      } catch (error) {
        console.log(error  );
      }

      //  this.data.days = response.data ;
    },




  syncProfile: async function () {
     /*  var token = window.$cookies.get("token");
      var system = window.$cookies.get("system"); */

      setTimeout(async() => {


      var username = window.$cookies.get("username");
      var token_system = window.$cookies.get("token_system");

      //   console.log ( system );
      this.showSpinner = true;
      this.$root.$children[0].showProgress = true ;


/*     var prove ;
    try {

        prove = await apiprofile.getProfile(
            username,
            token_system
            );

    } catch (error) {
        alert ( error.message );
        console.log(prove);

    } */




      var result;
      try {
         // alert ( username + " +" + token_system );
        result = await apiprofile.getProfile(
          username,
          token_system
        ).then( (response) => {
           // alert ("finshed");

           // console.log (response);
        this.showSpinner = false;
        this.$root.$children[0].showProgress = false ;

      try {

        this.profile = response.data.Result;
        this.profile.username = username ;
        //console.log("HERE",response.data.Result.iban);
/*         this.iban = response.data.Result.iban ;
        this.email = response.data.Result.mail ;
        this.codicefiscale = response.data.Result.codice_fiscale ;
        this.cellulare = response.data.Result.tel_cell ;
        this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ; */
        this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ;


            //this.monthArray = response.data.Timesheet;


        } catch (error) {
            console.log(error  );
            alert (error.message);
        }

            console.log (result);




        }  );
      } catch (error) {
          alert(error.message);

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false ;
      }

 }, 300);

      //  this.data.days = response.data ;
    },



    btn_add_tms_click() {
      console.log(1);
    },
    /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
    reply(par) {
      console.log(par);
    },
    validate() {
      //this.$root.$children[0].showProgress = true;

      var res = this.$refs.form.validate();
      console.log(res);
      if (!res) {

            this.$swal({ icon: "error", text: "Please check your data" });
      } else {

            console.log ( "Profile: " ,  this.profile  );

        this.savePassword ( ) ;


      }
    },



    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.titlenewrow{
    justify-content:center;padding-top:25px;padding-bottom:30px;
    font-weight: bolder;

}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
