import { render, staticRenderFns } from "./storicoClientiProposti.vue?vue&type=template&id=2ddfb8ea&scoped=true&"
import script from "./storicoClientiProposti.vue?vue&type=script&lang=js&"
export * from "./storicoClientiProposti.vue?vue&type=script&lang=js&"
import style0 from "./storicoClientiProposti.vue?vue&type=style&index=0&id=2ddfb8ea&scoped=true&lang=css&"
import style1 from "./storicoClientiProposti.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ddfb8ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VBtn,VCol,VImg,VListItem,VListItemTitle,VRow,VSheet})
