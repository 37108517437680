<template>
    <v-container fluid class="text-center d-flex align-center justify-center"
        style="max-width: 100%; min-width: 300px;padding-bottom:100px;">

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="(tile, key) in filteredAziende" :key="key"
                                    @click="manageClickAziende(tile)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetDipendenti">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Dipendenti

                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchDipendenti"></v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredDipendenti" :key="key"
                                    @click="manageClickDipendenti(objDip, 0)">
                                    <v-icon aria-hidden="false">mdi-account</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ objDip.fld_name }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetEmpty">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">

                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>

                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetGruppi">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Gruppi
                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchGruppi"></v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredGruppi" :key="key"
                                    @click="manageClickDipendenti(objDip, 1)">
                                    <v-icon aria-hidden="false">mdi-account-multiple</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ objDip.nome }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <v-dialog id='dialogMsgSend' content-class="dialogMsg" v-model="dialogMsgSend" width="100vw"
            style="min-width:100vw" max-width="500px">
            <v-card height="100vh" width="100vw">
                <v-card-title>

                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>

                            <v-col cols="12" sm="12" md="12">
                                <span class="v-input input">{{ "Messaggio a" }}<span class="corrente">{{
                                        nomeCompletoCorrente
                                }}</span> </span>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field id='titolo' models="titolo" label="Oggetto"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea id='body' outlined name="input-7-4" label="Testo" models="body">
                                </v-textarea>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <template>

                                    <span class="files">
                                        <label style="margin-left:11px;font-size:11px">Allegato</label>
                                        <input type="file" id="input-file-logo" name="logo"
                                            accept=".png,.jpg,.jpeg,.pdf" class="dropify" data-max-width="6610"
                                            data-max-height="6200" />
                                    </span>

                                </template>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                </v-card-actions>
            </v-card>
        </v-dialog>

        <template>

            <div>

                <v-dialog id='dialogMsg' content-class="dialogMsg" v-model="dialogMsg" width="100vw"
                    style="min-width:100vw" max-width="500px">

                    <v-card v-if="defaultItem.read == 1 || defaultItem.accepted == true" height="100vh" width="100vw">
                        <v-card-title>

                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>

                                    <v-col cols="12" sm="12" md="12">
                                        <span class="text-h5">{{ "Messaggio" }}</span>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field readonly v-model="defaultItem.title" label="Oggetto">
                                        </v-text-field>

                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea readonly outlined name="input-7-4" label="Testo"
                                            v-model="defaultItem.body"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                    </v-card>

                </v-dialog>

                <template>
                    <div class="cont-table-msg">

                        <div class="spin">
                            <ion-spinner name="crescent"></ion-spinner>
                        </div>

                        <div class="row conf_messaggi">
                            <div class=" " style="margin:0 auto;">
                                <div class="card">

                                    <div class="card-body" style="padding:0px">

                                        <div class="table-responsive" style="">

                                            <table id="myTable" style="margin-top:59px">
                                                <thead>
                                                    <tr>

                                                        <th class='all'>
                                                            <div class="col_n">N.</div>
                                                        </th>

                                                        <th class='all'></th>

                                                        <th class='all'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            Date</th>

                                                        <th class='all'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            Titolo</th>

                                                        <th class='desktop'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            Mittente</th>

                                                        <th class='all'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            Ricevente</th>

                                                        <th class='all'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            All.</th>

                                                    </tr>
                                                </thead>

                                                <tfoot>

                                                    <tr>
                                                        <th colspan="7">
                                                        </th>
                                                    </tr>
                                                </tfoot>

                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </template>

            </div>

        </template>

        <template>
            <modal name="popupAcceptRead" :clickToClose="false" :width="350" :height="180">

                <v-row>

                    <v-col cols="12" md="12" style="margin-top:10px!important">

                        <div style="font-size:12px;padding-left:7px;font-weight:bold;float:left">Accettazione</div>

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" md="12">

                        <div style="padding-left:7px;margin-top:-25px;font-size:12px!important" id="divChk">

                            <v-checkbox label="Dichiaro di accettare la lettura del messaggio"
                                style="font-size:12px!important" @click="acceptReadNew()"></v-checkbox>

                        </div>

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" md="12">

                        <div style="display:flex;justify-content:center">

                            <v-img alt="" class="btn-cancel-accept-read" contain src="@/assets/btn_cancel3.png"
                                max-width="30" max-height="30" style="cursor:pointer;color:black"
                                transition="scale-transition" />

                        </div>

                    </v-col>

                </v-row>

            </modal>
        </template>

    </v-container>
</template>

<script>
import apiprofile from "../utils/profile/apiprofile";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';
import _ from 'lodash';

import Vue from "vue";
import VModal from 'vue-js-modal';

import apitimesheet from "../utils/timesheet/apitimesheet";

export default {

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/

        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        Vue.use(VModal, {
            dialog: true
        });

        bus.$off("bottomIconClickEvent");

        window.perpage = 30;

        var pointerVue = this;

        this.dipendenti = this.$root.$children[0].dipendenti;

        this.gruppi = this.$root.$children[0].gruppi;

        this.aziende = this.$root.$children[0].aziende;

        //this.aziende.sort();

        console.log("AZI MSGS: ", this.aziende);

        this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '#btnRefresh', function (event) {
                event.preventDefault();

                console.log("REFRESH");

                pointerVue.getDataFromApi();

            });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_download":
                    var url = "https://app.emtool.eu/public/documents/" + this.currItem.attachment;
                    window.location.href = url;

                    break;
                case "btn_delete":
                    this.delMsg();
                    break;

                case "btn_delete_row":
                    this.delMsgRow();
                    break;

                case "btn_invia_msg":
                    this.sendMsg();
                    break;
                case "btn_companies":
                    //  this.setupButtons();
                    this.sheetAziende = true;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.sheetGruppi = false;
                    this.dialogMsgSend = false;
                    break;

                case "btn_dipendenti":
                    //  this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = true;
                    this.sheetGruppi = false;
                    this.dialogMsgSend = false;
                    break;

                case "btn_gruppi":

                    this.gruppi = this.$root.$children[0].gruppi;

                    //  this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.sheetGruppi = true;
                    this.dialogMsgSend = false;
                    break;

                case "btn_back":
                    this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetGruppi = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.dialogMsgSend = false;
                    this.dialogMsg = false;

                    this.closeSendMsg();

                    break;
                case "btn_aziende":

                    this.buttonsNewMessage();

                    this.dipendenti = this.$root.$children[0].dipendenti;
                    this.aziende = this.$root.$children[0].aziende;
                    this.globalArrAziende = this.$root.$children[0].globalArrAziende;

                    this.$root.$children[0].showProgress = true;

                    setTimeout(() => {
                        this.sheetAziende = true;
                        this.$root.$children[0].showProgress = false;

                    }, 300);

                    break;
                case "btn_add_tms":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_exit":
                    //alert("btn_add_x");
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_view":

                    console.log("CUR ITEM ROW: ", this.currItemRow);

                    if (this.currItemRow.read == 0) {

                        this.$modal.show('popupAcceptRead');
                    } else {

                        this.handleClick(this.currItemRow);

                    }

                    break;

                default:
                    break;
            }
        });

        this.setupButtons();

        this.setupGrid();

    },
    data: () => ({

        page: 1,

        currentFile: null,
        selecting: 0,
        currItem: {},
        currItemRow: {},
        currGruppo: {},
        switch1: true,
        nomeCorrente: "",
        nomeCompletoCorrente: "",
        titolo: "",
        body: "",
        aziende: [],
        gruppi: [],
        globalArrAziende: [],
        dipendenti: [],
        search: '',
        sheetGruppi: false,
        sheetAziende: false,
        sheetEmpty: false,
        sheetDipendenti: false,
        searchDipendenti: '',
        searchGruppi: '',

        currAzienda: "",

        defaultItem: {
            title: 'ti',
            body: '',
            read: 0,
            accepted: 0,
            id: 0

        },
        defaultItemSend: {
            title: '',
            body: '',

        },

        dialogMsgSend: false,
        dialogMsg: false,

        singleSelect: true,

        totalDesserts: 0,
        desserts: [],
        loading: true,
        options: {},
        headers: [{
            text: ' ',
            value: 'read',
            align: 'start'
        },
        {
            text: 'Date',
            value: 'created_at',
            align: 'start'
        },
        {
            text: 'Title',

            sortable: true,
            value: 'title',
            align: 'start'
        },
        {
            text: 'Receiver',

            sortable: true,
            value: 'dest',
            align: 'start'
        },

        ],

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),


    methods: {

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/" + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;

            var filtri = `<img src='/emt/img/update-left-rotation.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' />`;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'read_formatted',
                        orderable: false,
                        name: 'read_formatted',
                        width: '1%'
                    },

                    {
                        data: 'created_at',
                        orderable: false,
                        name: 'created_at',
                        width: '1%',

                        render: function (data, row) {
                            console.log(data, row);

                            var date = "";
                            try {

                                date = data.substring(0, 10);
                            } catch (error) {
                                console.log(error);
                            }

                            var hour = "";
                            try {

                                hour = data.split(" ")[1].slice(0, 5);
                            } catch (error) {
                                console.log(error);
                            }

                            var html = "<div class='font-row-grid-datetime-mobile'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                            return html;
                        }

                    },

                    {
                        data: 'title_formatted',
                        orderable: false,
                        name: 'title_formatted',
                        width: '5%',
                    },

                    {
                        data: 'cmp_mitt',
                        orderable: false,
                        name: 'cmp_mitt',
                        width: '5%',
                    },

                    {
                        data: 'dest_formatted',
                        orderable: false,
                        name: 'dest_formatted',
                        width: '4%'
                    },

                    {
                        data: 'attach_present',
                        orderable: false,
                        name: 'attach_present',
                        width: '4%'
                    },

                ];

                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {

                        url: pointerVue.getUrlDomainApi("newGetMessagesApi") + '?username=' + v_token + "&productid=emt",

                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            var valSearch = $("#myTable_filter input").val();

                            var search = {
                                "value": $("#myTable_filter input").val(),
                                "regex": false
                            };

                            if (valSearch.length > 0) {
                                valori.search = search;
                                valori.columns = window.columns;
                                console.log(window.columns);
                            }

                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                    }

                });

                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomainApi("newGetMessagesApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Messaggi</div>
                    ` + filtri + `

                </div>
            `);

                $('body').on('click', '.selaggiorna', function () {

                    window.table.ajax.reload();

                });

                $('body').on('click', '.conf_messaggi tr', function () {

                    setTimeout(() => {

                        console.log("ROW DATA: ", window.table.row(this).data());

                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        $("tr").removeClass("active-row");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        var item = window.table.row(this).data();

                        console.log("ITEM: ", item);

                        pointerVue.currItemRow = item;

                        if (set_cls_active == 1) {

                            $(this).addClass("active-row");

                            pointerVue.hideShowButtons(true);

                        } else {

                            pointerVue.hideShowButtons(false);

                        }

                    }, 100);

                });

                $('body').on('click', '.btn-cancel-accept-read', function (event) {
                    console.log(event);

                    pointerVue.$modal.hide('popupAcceptRead');

                });

            });

        },

        selectFile(file) {
            // this.progress = 0;
            this.currentFile = file;
        },

        alerta: function (val) {
            this.selecting = 1;
            console.log(val);
            setTimeout(() => {

                this.selecting = 0;
            }, 300);
        },
        acceptRead: function () {

            this.updateMessage(this.currItem.message_id);

            $("tr.active-row").find(".msgread0").removeClass("msgread0");
        },

        acceptReadNew: function () {

            this.updateMessage(this.currItemRow.message_id);

            this.$modal.hide('popupAcceptRead');

            this.currItemRow.read = 1;

            this.handleClick(this.currItemRow);

        },

        addBackDeleteBtn: function () {

            var pulsantis = [];
            pulsantis.push(

                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back.png",
                    link: "/back",
                    class: "inverted",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro"
                }


                // {
                //     text: "",
                //     icon: "mdi-arrow-left",
                //     link: "/back",
                //     id: "btn_back",
                //     disabled: false,
                // }

            );

            if (this.currItem.attachment) {
                pulsantis.push({
                    text: "",
                    icon: "mdi-download-outline",
                    link: "/download",
                    id: "btn_download",
                    disabled: false,
                });
            }

            setTimeout(() => {
                $("#btn_download").attr("href", "https://app.emtool.eu/public/documents/" + this.currItem.attachment);

            }, 300);

            var a = window.$cookies.get("a");
            if (a == 'Y' || a == 'S') {

                pulsantis.push(

                    {
                        text: "",
                        icon: "mdi-delete",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__new_icon_delete_32.png",
                        class: "inverted",
                        link: "/delete",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina"
                    }



                    // {
                    //     text: "",
                    //     icon: "mdi-delete",
                    //     link: "/delete",
                    //     id: "btn_delete",
                    //     disabled: false,
                    // }


                );
            }

            this.$root.$children[0].bnavbuttons = pulsantis;

        },
        addBackBtn: function () {

            var pulsantis = [];
            pulsantis.push(

                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back.png",
                    link: "/back",
                    class: "inverted",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro"
                }



                // {
                //     text: "",
                //     icon: "mdi-arrow-left",
                //     link: "/back",
                //     id: "btn_back",
                //     disabled: false,
                // }


            );


            this.$root.$children[0].bnavbuttons = pulsantis;

        },
        handleClick(row) {

            if (this.selecting != 1) {
                // set active row and deselect others
                console.log("row", row);
                this.openMsgDialog(row);
                //this.addBackBtn();
                this.addBackDeleteBtn();

                this.desserts.map((item, index) => {
                    item.selected = item === row

                    this.$set(this.desserts, index, item)
                })

                // or just do something with your current clicked row item data
                console.log(row.sugar)
            }

        },

        manageClose: function () {

            this.setupButtons();
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.sheetDipendenti = false;
            this.sheetGruppi = false;

        },
        changeGruppi: function () {
            this.sheetDipendenti = !this.sheetDipendenti;
            this.sheetGruppi = !this.sheetGruppi;

            /* if(!this.switch1){
                this.sheetDipendenti = false ;
                this.sheetGruppi = true ;
            }
            else
                {
                     this.sheetDipendenti = true ;
                    this.sheetGruppi = false ;
                } */
        },

        delMsg: function () {

            var response;
            console.log("this.currItem", this.currItem);
            try {
                response = this.$api.deleteMessage(
                    this.currItem.message_id

                );

                this.$swal({
                    icon: "success",
                    text: "Your message has been deleted correctly",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    this.dialogMsg = false;
                    this.setupButtons();
                }, 2000);

            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            console.log(response);

        },

        delMsgRow: function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Do you want to delete the selected item ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                })
                .then(async function (result) {

                    if (result.value == true) {

                        console.log("that.currItemRow: ", that.currItemRow);

                        try {
                            var response = that.$api.deleteMessage(
                                that.currItemRow.message_id,
                                v_token
                            );

                            console.log("res from deleteMessage", response);

                            that.$swal({
                                icon: "success",
                                text: "Your message has been deleted correctly",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        } catch (error) {
                            console.log("ERROR DELETE: ", error);
                        }

                    }

                });



        },

        sendMsg: function () {

            var title = $("#titolo").val();
            var body = $("#body").val();

            console.log("ATTACHMENT: ", $('#input-file-logo')[0].files);

            this.currentFile = $('#input-file-logo')[0].files;


            var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");
            var response;

            console.log(" this.currGruppo", this.currGruppo.nome);
            var isgroup = 0;
            if (this.currGruppo.nome !== undefined) {
                isgroup = 1;

            }
            if (title.length > 0) {
                try {
                    response = this.$api.insertMessage(
                        this.nomeCorrente,
                        token,
                        username,
                        title,
                        body,
                        isgroup,
                        this.currentFile

                    );

                    this.$swal({
                        icon: "success",
                        text: "Your message has been sent correctly",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        this.closeSendMsg();

                        //this.getDataFromApi();
                    }, 2000);

                    // setTimeout(() => {
                    //     window.table.ajax.reload();

                    // }, 100);

                } catch (error) {
                    this.showSpinner = false;
                    console.log(error);
                }
            } else {
                this.$swal({
                    icon: "error",
                    text: "Please insert the title and body message"
                });

            }

            console.log(response);

        },
        changeDip: function (selectObj) {
            console.log(selectObj);
            var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    selNameDip = dip.fld_name;
                    //set display
                    this.nomeCorrente = atob(dip.v_user);

                    this.nomeCompletoCorrente = dip.fld_name;

                }
            }
            console.log(selNameDip);
            //  this.$root.$children[0].curDipTop = selNameDip;

        },

        manageClickDipendenti: function (dipendente, isGroup) {

            console.log("MANAGE_CLICK_DIPENDENTI");

            console.log(isGroup);
            this.sheetDipendenti = false;
            this.sheetGruppi = false;
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.currDipendente = dipendente.resourceid;
            this.dialogMsgSend = true;

            if (this.currGruppo) {
                this.currGruppo = dipendente;
                this.nomeCorrente = dipendente.nome;

                this.nomeCompletoCorrente = this.currGruppo.nome;

                console.log("this.currGruppo", this.currGruppo);

            } else {
                this.currGruppo = {};

            }

            // this.$root.$children[0].bnavbuttons.push ( {}  );

            //            this.resetDay();

            setTimeout(() => {
                // var pulsantis = [{
                //     text: "Dashboard",
                //     icon: "mdi-home-circle",
                //     link: "/dash",
                //     id: "btn_exit",
                //     disabled: false,
                // }, ];

                var pulsantis = [];

                pulsantis.push(

                    {
                        text: "Indietro",
                        icon: "mdi-arrow-left",
                        image: "https://app.emtool.eu/public/_lib/img/back.png",
                        link: "/back",
                        class: "inverted",
                        id: "btn_back",
                        disabled: false,
                        title: "Indietro"
                    }



                    // {
                    //     text: "",
                    //     icon: "mdi-arrow-left",
                    //     link: "/back",
                    //     id: "btn_back",
                    //     disabled: false,
                    // }

                );

                pulsantis.push({
                    text: "",
                    icon: "mdi-email-send-outline",
                    link: "/invia",
                    id: "btn_invia_msg",
                    disabled: false,
                });

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

            setTimeout(() => {

                this.formatDropify();

            }, 100);

            this.changeDip();
            setTimeout(() => {
                //  this.syncMonth();
            }, 100);

        },

        formatDropify: function () {

            var oggDrop = $('.dropify').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            //console.log(oggDrop);

            oggDrop.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

            });

        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            var that = this;

            this.currDipendente = "";
            //var arrAziende = [];
            //var arrDipendenti = [];
            //var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            console.log("Elenco aziende:", this.aziende);
            console.log("Azienda Sel: ", this.currAzienda);

            var v_token = window.$cookies.get("token");


            this.$root.$children[0].showProgress = true;

            var response = await apitimesheet.getDipendenti(
                this.currAzienda,
                v_token,
                2022,
                6
            ).then((res) => {

                this.$root.$children[0].showProgress = false;

                //that.$root.$children[0].showProgress = false;
                console.log("res from getDipendenti", res);


                var arrDipendenti = [];

                for (var dip of res.data.Dipendenti) {

                    arrDipendenti.push(dip);

                }

                this.dipendenti = arrDipendenti;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }


            );




            // for (var az of aziende) {
            //     var currArAzienda = az[1].fld_name;
            //     console.log("az", az);
            //     arrAziende.push(az[1].fld_name);
            //     var dipArray = az[1].dipendenti;
            //     for (var dip of dipArray) {
            //         if (currArAzienda == this.currAzienda) {
            //             arrDipendenti.push(dip);
            //         }
            //     }
            // }
            // this.dipendenti = arrDipendenti;

        },

        manageClickAziende: async function (azienda) {

            this.currAzienda = azienda;
            this.buttonsNewMessage();
            await this.syncArrayDipendenti();

            console.log("CurAzienda", this.currAzienda);
            console.log(this.dipendenti);

            setTimeout(() => {

                this.sheetEmpty = true;

                //    this.sheetDipendenti = true;
            }, 300);

        },

        openMsgDialog: function (item) {

            console.log("ITEM: ", item);

            // item.read = "1";

            this.currItem = item;
            this.dialogMsg = true;
            //this.editedItem = Object.assign({}, this.defaultItem)
            this.defaultItem.title = item.title;
            this.defaultItem.body = item.desc;
            this.defaultItem.read = item.read;
            this.defaultItem.id = item.id;

            this.defaultItem.accepted = item.read;

            //  this.updateMessage(item.id);

        },

        closeSendMsg() {
            this.dialogMsgSend = false;
            this.setupButtons();

            setTimeout(() => {
                window.table.ajax.reload();

            }, 100);

        },

        close() {
            this.dialogMsg = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        getDataFromApi() {
            this.loading = true
            this.realApiCall().then(data => {
                this.desserts = data.items
                this.totalDesserts = data.total
                this.loading = false
            })
        },

        realApiCall: async function () {
            let items = await this.getMessages();
            //console.log ( "ciao",1 );

            return new Promise((resolve, reject) => {
                //console.log ( "ciao",2 );
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options
                console.log(reject);

                //const total = items.length

                const total = this.totalDesserts;
                //   alert(total);

                if (sortBy.length === 1 && sortDesc.length === 1) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]]
                        const sortB = b[sortBy[0]]

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                    })
                }
                console.log(itemsPerPage, page);
                /* if (itemsPerPage > 0) {
                    items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                } */

                setTimeout(() => {
                    resolve({
                        items,
                        total,
                    })
                }, 10)
            })
        },
        getMessages: async function () {

            var response;

            var token = window.$cookies.get("token");

            console.log("Options :", this.options);
            var itemsPerPage = this.options.itemsPerPage;

            var start = (this.options.itemsPerPage * this.options.page) - this.options.itemsPerPage;
            var arrMsg = [];
            try {
                response = await this.$api.getMessages(
                    token,
                    start,
                    itemsPerPage
                );

                this.totalDesserts = response.data.Messages.msg_tot;
                this.$root.$children[0].totalMsg = response.data.Messages.msg_tot_nr;

                var msg = response.data.Messages.pagine[0].messaggi;
                console.log("msg", msg);
                /*  for ( let [key] in msg)
                 {
                     //console.log ( "messaggio", msg[key] );
                     arrMsg.push ( msg[key] ) ;

                 } */

                for (let [key, value] of Object.entries(msg)) {
                    console.log("Messaggio", key, value);
                    arrMsg.push(value);
                }

            } catch (error) {
                // this.showSpinner = false;
                console.log(error);
            }
            console.log(response);
            return arrMsg;


        },

        setupButtons: async function () {
            setTimeout(() => {

                var pulsantis = [];

                var a = window.$cookies.get("a");
                var b = window.$cookies.get("b");


                if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                    pulsantis.push(

                        {
                            text: "",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                            class: "inverted",
                            link: "/dash",
                            id: "btn_aziende",
                            disabled: false,
                            title: "Aggiungi"
                        },


                    );
                }
                this.$root.$children[0].bnavbuttons = pulsantis;
            }, 100);
        },

        hideShowButtons: async function (v_enable) {

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");

            console.log("HIDE SHOW BUTTONS A: ", a);
            console.log("HIDE SHOW BUTTONS B: ", b);

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                        pulsantis.push(



                            {
                                text: "",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                                class: "inverted",
                                link: "/dash",
                                id: "btn_aziende",
                                disabled: false,
                                title: "Aggiungi"
                            }

                        );

                    }

                }

                if (v_enable == true) {

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                        pulsantis.push(

                            {
                                text: "",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                                class: "inverted",
                                link: "/dash",
                                id: "btn_aziende",
                                disabled: false,
                                title: "Aggiungi"
                            }


                        );
                    }

                    pulsantis.push(

                        {
                            text: "",
                            icon: "mdi-eye",
                            image: "https://app.emtool.eu/public/_lib/img/view.png",
                            class: "inverted",
                            link: "/dash",
                            id: "btn_view",
                            disabled: false,
                            title: "Vedi"
                        }




                    );

                    if (a == 'Y' || a == 'S') {

                        pulsantis.push(

                            {
                                text: "",
                                icon: "mdi-delete",
                                image: "https://app.emtool.eu/public/_lib/img/usr__NM__new_icon_delete_32.png",
                                class: "inverted",
                                link: "/delete",
                                id: "btn_delete_row",
                                disabled: false,
                                title: "Elimina"
                            }




                        );

                    }

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        buttonsNewMessage: async function () {
            setTimeout(() => {
                var pulsantis = [
                    /* {
                                        text: "Dashboard",
                                        nsertcon: "mdi-home-circle",
                                        link: "/dash",
                                        id: "btn_exit",
                                        disabled: false,
                                    }, */
                ];

                pulsantis.push(

                    {
                        text: "Indietro",
                        icon: "mdi-arrow-left",
                        image: "https://app.emtool.eu/public/_lib/img/back.png",
                        link: "/confOrariChoice",
                        class: "inverted",
                        id: "btn_back",
                        disabled: false,
                        title: "Indietro"
                    }



                );


                if (this.currAzienda) {
                    pulsantis.push({
                        text: "",
                        icon: "mdi-account",
                        link: "/dipendenti",
                        id: "btn_dipendenti",
                        disabled: false,
                    });

                    pulsantis.push({
                        text: "",
                        icon: "mdi-account-multiple",
                        link: "/gruppi",
                        id: "btn_gruppi",
                        disabled: false,
                    });
                }

                //}
                this.$root.$children[0].bnavbuttons = pulsantis;
            }, 100);
        },

        updateMessage: async function (id_msg) {

            //  var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");

            this.$root.$children[0].totalMsg = this.$root.$children[0].totalMsg - 1;

            var response;

            // console.log(username, token_system);
            try {
                response = this.$api.updateStatusMsgApi(
                    token,
                    id_msg
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }
            console.log(response);
        },

        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
#divChk .v-label {
    font-size: 12px !important
}

.cont-table-msg {
    font-size: 10px;
    padding-top: 4px;
    margin: 0 auto;
}


.cont-table-msg .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.divCont {
    max-width: 500px;
    text-align: center;
    margin: auto;
}

.conf_messaggi .col_n {}

.conf_messaggi .selaggiorna {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.header_style {

    background-color: #01adff;
    padding: 5px 5px 5px 5px;
    text-align: left;

}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #01adff;

}

.pay a {
    color: #01adff;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #01adff;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.dialogMsg {
    min-width: 100vw !important;
}

span.corrente {
    margin-left: 4px;
}

.imgread1 {
    visibility: hidden;
}

.imgread0 {
    visibility: visible;
    width: 22px;
}

.msgread1 {
    font-weight: normal;
}

.msgread0 {
    font-weight: bold;
}

.v-application .elevation-1 {
    box-shadow: none !important;
    ;
}

span.hour {
    font-size: 10px;
}

.msgDest {
    font-size: 10px;
}

.msgDesc {
    min-width: 66px;
    font-size: 10px;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 100% !important;
    margin-bottom: 20px;
    margin-left: 0px;
}

.files label {
    margin-left: 0px !important;
}

.conf_messaggi #myTable_wrapper {
    min-width: initial;
    min-width: 700px;
    max-width: 700px;
    width: 700px;
}

.conf_messaggi .nome_parente {
    min-width: 200px;
}

.conf_messaggi .inserito {
    min-width: 70px;
}

.conf_messaggi .osp_assoc {
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.conf_messaggi .mail {
    min-width: 200px;
}

.conf_messaggi td:first-child {
    padding: 10px !important;
}

.conf_messaggi #myTable_filter {
    padding-top: 1px;
    padding-bottom: 1px;
    position: fixed;
    left: 0px;
    max-width: 100%;
}

.conf_messaggi #myTable {
    margin-right: 3px;
}

.conf_messaggi table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.conf_messaggi table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 30px !important;
}

.conf_messaggi th {
    padding-left: 5px !important;
}

.conf_messaggi ion-datetime.dateBook {
    margin-left: 16px;
}


.conf_messaggi .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.conf_messaggi .font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 64px !important;
    padding-left: 0px !important;
}

.conf_messaggi #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
}

.conf_messaggi div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.conf_messaggi #myTable_wrapper {
    padding-top: 0px;
}

.conf_messaggi .text_title {
    background-color: #01adff;
    color: white;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 80px;
    text-align: left;
}

.conf_messaggi .cl_active {
    display: inline-flex;
    align-items: center;
    /* justify-content: flex-start!important; */
    /*  text-align: center; */
    min-width: 100%;

    justify-content: center;
}

.conf_messaggi .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 100px;
    padding-left: 10px;
    justify-content: inherit;

}

@media screen and (max-width: 768px) {

    .header_style {

        background-color: #01adff;
        padding: 5px 5px 5px 5px;
        text-align: left;

    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

    .conf_messaggi .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .conf_messaggi #myTable {
        max-width: 100vw;
        margin-right: 0px;
        min-width: 100vw;
        position: relative;
        /*   left: 12px; */
        width: 100vw !important;

    }

    .conf_messaggi #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .conf_messaggi .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 100vw;
        left: 0px;
        position: fixed;

    }

    .conf_messaggi #myTable_wrapper {
        min-width: initial;
        min-width: 500px;
        max-width: 500px;
        width: 500px;
    }

    .conf_messaggi .col_n {
        margin-left: 21px;
    }

}
</style>
