import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APISettori {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async initInsert(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertSettore(settore, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in settore) {
            formData.append(key, settore[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitSettoriInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initEdit(id, user, v_prod) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);
        formData.append("v_prod", v_prod);


        return axios.post(this.getUrlDomain("TwoRecruitSettoriInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateSettore(settore, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        for (var key in settore) {
            formData.append(key, settore[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitSettoriUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteSettore(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);


        return axios.post(this.getUrlDomain("TwoRecruitSettoriDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user) {

        let formData = new FormData();

        formData.append("username", user);


        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

}

export default new APISettori({
    url: "https://services.ebadge.it/public/api/"
})
