<template>
    <div>
      
        
    </div> 
</template>

<script>
import _backslider from "./_backslider.vue";
//import apidoor from "./door/apidoor";

// import { bus  } from "./../emtsrc/src/main";

export default {
  props: ['app','buso'],
  mounted:function(){
    
/*     console.log("BUS:",this.bus);

 this.bus.$on("bottomIconClickEvent", (data) => {
 console.log(data);
 alert(1);
 }); */

 if (this.app=='emt')
 {

 /*  console.log ("busemt",bus);
        bus.$on("bottomIconClickEvent", (data) => {
        console.log(data);
        alert(1);
        });   */
 }
 

    this.$router.addRoute({
      path: "/backslider",
      name: "backslider",
      component: _backslider,
      props: { app: this.app }

    });

  },
  data() {
    return {
      // counter only uses this.initialCounter as the initial value;
      // it is disconnected from future prop updates.
      counter: this.initialCounter
    }
  }
}
</script>