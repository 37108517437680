import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIRegioni {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async initInsert(user, v_prod) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_prod", v_prod);

        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertRegione(regione, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in regione) {
            formData.append(key, regione[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitRegioniInsApiJM"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initEdit(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitEditApiJM"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateRegione(regione, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        for (var key in regione) {
            formData.append(key, regione[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitRegioniUpdApiJM"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteRegione(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitRegioniDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user, v_prod) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_prod", v_prod);


        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

}

export default new APIRegioni({
    url: "https://services.ebadge.it/public/api/"
})
