import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIGroups {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getAziendeGlobal(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtGetAziendeGlobalApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsert(user)
  {

    let formData = new FormData();

    formData.append("username",  user);
    
   
    return axios.post(this.getUrlDomain("EmtInitAddGruppoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async saveGroup(gruppo, user, id, ids_users, mails)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
    formData.append("ids_users",  ids_users);
    formData.append("mails",  mails);

    for ( var key in gruppo ) {
      formData.append(key, gruppo[key]);
   }
    
   
    return axios.post(this.getUrlDomain("EmtSaveGruppoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async initEdit(id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
    
    return axios.post(this.getUrlDomain("EmtInitEditGruppoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async initEditJM(id, user, portale)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
    formData.append("portale",  portale);
    
    return axios.post(this.getUrlDomain("EmtInitEditGruppoApiJM") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




  async deleteMail(sel_mails, ids)
  {

    let formData = new FormData();

    formData.append("v_sel_mails",  sel_mails);
    formData.append("v_ids",  ids);
    
    return axios.post(this.getUrlDomain("EmtEditGruppoDeleteMailApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async deleteGruppo(id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
   
    return axios.post(this.getUrlDomain("EmtDeleteGruppoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async getAziendeGlobalJM(user, portale) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("portale", portale);

    return axios.post(this.getUrlDomain("JMGetAziendeGlobalApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initInsertJM(user, portale)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("portale", portale);
    
   
    return axios.post(this.getUrlDomain("EmtInitAddGruppoApiJM") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getAziendaCareersGlobalJM(v_url) {

    let formData = new FormData();

    formData.append("v_url", v_url);

    return axios.post(this.getUrlDomain("JMGetAziendaCareersGlobalApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





}

export default new APIGroups({
  url: "https://services.ebadge.it/public/api/"
})
