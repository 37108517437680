<template>
    <div>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <!-- START -->
                                <div class="sidemenu__sub-items-container" id="menu-12">


                                    <div class="sidemenu__group-container">
                                        <div v-if="((viewComuni == 1) || (viewProvince == 1) || (viewRegioni == 1))"
                                            class="group__text" style="border-color: #01adff;">
                                            <div style="margin-left:5px">Annunci</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewComuni == 1" @click="goto('/comuni')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_comuni.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Comuni</span>
                                            </a>

                                            <a v-if="viewProvince == 1" @click="goto('/province')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_province.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Province</span>
                                            </a>

                                            <a v-if="viewRegioni == 1" @click="goto('/regioni')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_regioni.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Regioni</span>
                                            </a>

                                        </ul>
                                    </div>

                                    <div class="sidemenu__group-container">

                                        <div v-if="viewCVCandidato == 1" class="group__text" :style="txtStyle">
                                            <div style="margin-left:5px">Candidato</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewCVCandidato == 1 && isJobmule == 1" @click="goto('/CvCandidato')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_cv_ricevuti.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">CV</span>
                                            </a>


                                            <a v-if="viewCVCandidato == 1 && isCareers == 1" @click="goto('/CvCandidato')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/careers_cv_ricevuti.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">CV</span>
                                            </a>



                                        </ul>


                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div v-if="viewStatiIter == 1" class="group__text" style="border-color: #01adff;">
                                            <div style="margin-left:5px">Colloqui</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewStatiIter == 1" @click="goto('/StatiIterColloquio')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_colloquio.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Stati Iter</span>
                                            </a>



                                        </ul>
                                    </div>

                                    <div class="sidemenu__group-container">

                                        <div v-if="((viewLogoPortale == 1) || (viewImmaginiSfondoPortale == 1) || (viewAziendaPortale == 1) || (viewRSSPortale == 1) || (viewPoweredBy == 1) || (viewPrivacyPortale == 1) || (viewTermOfUse == 1) || (viewLogoPortaleJobmule == 1) || (viewImmaginiSfondoPortaleJobmule == 1) || (viewAziendaPortaleJobmule == 1) || (viewRSSPortaleJobmule == 1) || (viewPoweredByJobmule == 1) || (viewPrivacyPortaleJobmule == 1) || (viewTermOfUseJobmule == 1) || (viewPrivacyHomePortale == 1) || (viewPrivacyHomePortaleJobmule == 1))"
                                            class="group__text" :style="txtStyle">
                                            <div style="margin-left:5px">Configurazione Portale</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewLogoPortale == 1" @click="goto('/impostaLogo')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/imposta-logo-careers.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">Logo</span>
                                            </a>


                                            <a v-if="viewLogoPortaleJobmule == 1" @click="goto('/impostaLogo')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/imposta-logo-jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">Logo</span>
                                            </a>


                                            <a v-if="viewImmaginiSfondoPortale == 1" @click="goto('/imagesSfondo')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/immagini_sfondo_home.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">Immagini Sfondo</span>
                                            </a>

                                            <a v-if="viewImmaginiSfondoPortaleJobmule == 1" @click="goto('/imagesSfondo')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/slider_jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">Immagini Sfondo</span>
                                            </a>


                                            <a v-if="viewAziendaPortale == 1" @click="goto('/confAzienda')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/aziende-careers.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">Azienda</span>
                                            </a>

                                            <a v-if="viewAziendaPortaleJobmule == 1" @click="goto('/confAzienda')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/aziende-jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">Azienda</span>
                                            </a>

                                            <a v-if="viewRSSPortale == 1" @click="goto('/configRss')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain src="@/assets/rss-careers.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />


                                                <span class="subitem__text">RSS</span>
                                            </a>

                                            <a v-if="viewRSSPortaleJobmule == 1" @click="goto('/configRss')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain src="@/assets/rss-jobmule.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />


                                                <span class="subitem__text">RSS</span>
                                            </a>

                                            <a v-if="viewPoweredBy == 1" @click="goto('/configCreatedBy')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/created-by-careers.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">Powered By</span>
                                            </a>

                                            <a v-if="viewPoweredByJobmule == 1" @click="goto('/configCreatedBy')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/created-by-jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />


                                                <span class="subitem__text">Powered By</span>
                                            </a>

                                            <a v-if="viewPrivacyPortale == 1" @click="goto('/configPrivacy')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/privacy-careers.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />

                                                <span class="subitem__text">Privacy</span>
                                            </a>


                                            <a v-if="viewPrivacyHomePortale == 1" @click="goto('/configPrivacyHome')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/privacy-careers.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />

                                                <span class="subitem__text">Privacy Home</span>
                                            </a>



                                            <a v-if="viewPrivacyPortaleJobmule == 1" @click="goto('/configPrivacy')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/privacy-jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />

                                                <span class="subitem__text">Privacy</span>
                                            </a>

                                            <a v-if="viewPrivacyHomePortaleJobmule == 1" @click="goto('/configPrivacyHome')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/privacy-jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />

                                                <span class="subitem__text">Privacy Home</span>
                                            </a>

                                            <a v-if="viewTermOfUse == 1" @click="goto('/configTermOfUse')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/term-of-use-careers.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />

                                                <span class="subitem__text">Term Of Use</span>
                                            </a>

                                            <a v-if="viewTermOfUseJobmule == 1" @click="goto('/configTermOfUse')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/term-of-use-jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />

                                                <span class="subitem__text">Term Of Use</span>
                                            </a>




                                        </ul>


                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div v-if="(viewSottoCategorie == 1)" class="group__text"
                                            style="border-color: #01adff;">
                                            <div style="margin-left:5px">Functional Area</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewSottoCategorie == 1" mid="mnu_gest_company"
                                                @click="goto('/sottocategorie')" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_sottocategorie.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">SottoCategorie</span>
                                            </a>

                                        </ul>
                                    </div>



                                    <div class="sidemenu__group-container">
                                        <div v-if="(viewNewsletter == 1 || viewInvioNewsletter == 1)" class="group__text"
                                            :style="txtStyle">
                                            <div style="margin-left:5px">Newsletter</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewNewsletter == 1 && isJobmule == 1" @click="goto('/newsletter')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/newsletter_jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Newsletter</span>
                                            </a>


                                            <a v-if="viewNewsletter == 1 && isCareers == 1" @click="goto('/newsletter')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/newsletter_careers.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Newsletter</span>
                                            </a>

                                            <a v-if="viewInvioNewsletter == 1 && isJobmule == 1"
                                                @click="goto('/confInvioNewsletter')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/configurazione invio mail jobmule.png" max-width="32"
                                                    max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Invio</span>
                                            </a>



                                        </ul>
                                    </div>







                                    <div class="sidemenu__group-container">
                                        <div v-if="((viewAnnunci == 1) || (viewCandidati == 1) || (viewCVRicevuti == 1) || (viewReferenti == 1) || (viewRicerca == 1))"
                                            class="group__text" :style="txtStyle">
                                            <div style="margin-left:5px">Gestione</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewAnnunci == 1" @click="goto('/annunci')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_annunci.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Annunci</span>
                                            </a>

                                            <a v-if="viewCandidati == 1" @click="goto('/candidati')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_candidati.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Candidati</span>
                                            </a>

                                            <a v-if="viewCVRicevuti == 1" @click="goto('/CvRicevuti')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_cv_ricevuti.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">CV Ricevuti</span>
                                            </a>

                                            <a v-if="viewReferenti == 1" @click="goto('/referenti')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_referenti.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Referenti</span>
                                            </a>

                                            <a v-if="viewRicerca == 1" @click="goto('/ricercaCandidati')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_ricerca.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Ricerca</span>
                                            </a>








                                        </ul>
                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div v-if="((viewAziende == 1) || (viewCategorie == 1) || (viewGiornataLavoroTipo == 1) || (viewPrivacy == 1) || (viewSettore == 1) || (viewSottoCategorie == 1) || (viewStudi == 1) || (viewTipoContratto == 1) || (viewGruppi == 1))"
                                            class="group__text" :style="txtStyle">
                                            <div style="margin-left:5px">Impostazioni</div>
                                        </div>
                                        <ul class="group__items">


                                            <a v-if="viewAziende == 1" @click="goto('/initCompany')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/aziende-jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Aziende</span>
                                            </a>


                                            <a v-if="viewGiornataLavoroTipo == 1" @click="goto('/giornataLavoroTipo')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_giornata_di_lavoro.png" max-width="32"
                                                    max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Giornata Lavoro Tipo</span>
                                            </a>

                                            <a v-if="viewPrivacy == 1" @click="goto('/privacy')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/privacy-careers-non-portale.png" max-width="31"
                                                    max-height="31" transition="scale-transition"
                                                    style="margin-top:4px!important" />
                                                <span class="subitem__text">Privacy</span>
                                            </a>

                                            <a v-if="viewSettore == 1" mid="mnu_gest_company" @click="goto('/settori')"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_settore.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Settore</span>
                                            </a>



                                            <a v-if="viewStudi == 1" @click="goto('/studi')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_studi_minimi.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Studi</span>
                                            </a>

                                            <a v-if="viewTipoContratto == 1" @click="goto('/tipiContratti')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_tipo_contr.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Tipo Contratto</span>
                                            </a>


                                            <a v-if="viewGruppi == 1 && isJobmule == 1" @click="goto('/initGroups')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/groups_jobmule.png" max-width="31" max-height="31"
                                                    transition="scale-transition" style="margin-top:4px!important" />
                                                <span class="subitem__text">Gruppi</span>
                                            </a>

                                            <a v-if="viewGruppi == 1 && isCareers == 1" @click="goto('/initGroups')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/groups_careers.png" max-width="31" max-height="31"
                                                    transition="scale-transition" style="margin-top:4px!important" />
                                                <span class="subitem__text">Gruppi</span>
                                            </a>





                                        </ul>
                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div v-if="(viewCategorie == 1)" class="group__text" style="border-color: #01adff;">
                                            <div style="margin-left:5px">Industries</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewCategorie == 1" @click="goto('/categorie')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_categorie.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Categorie</span>
                                            </a>

                                        </ul>
                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div v-if="viewSlider == 1" class="group__text" style="border-color: #01adff;">
                                            <div style="margin-left:5px">Login</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewSlider == 1" @click="goto('/backslider')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/slider_jobmule.png" max-width="31" max-height="31"
                                                    transition="scale-transition" style="margin-top:4px!important" />
                                                <span class="subitem__text">Slider</span>
                                            </a>

                                        </ul>
                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div v-if="((viewMailGruppi == 1) || (viewMailInvio == 1))" class="group__text"
                                            style="border-color: #01adff;">
                                            <div style="margin-left:5px">Mail Report Annunci</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewMailGruppi == 1" @click="goto('/mailGruppo')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/gruppo_mail_jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Gruppi</span>
                                            </a>


                                            <a v-if="viewMailInvio == 1" @click="goto('/confInvioMail')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/configurazione invio mail jobmule.png" max-width="32"
                                                    max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Invio</span>
                                            </a>

                                        </ul>
                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div v-if="((viewCatSkills == 1) || (viewSkills == 1))" class="group__text"
                                            style="border-color: #01adff;">
                                            <div style="margin-left:5px">Skills</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewCatSkills == 1" mid="mnu_gest_company"
                                                @click="goto('/categorieSkills')" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_skill_category.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Categorie</span>
                                            </a>

                                            <a v-if="viewSkills == 1" mid="mnu_gest_company" @click="goto('/skills')"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/jobmule_skills.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Skills</span>
                                            </a>

                                        </ul>
                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div v-if="viewProfilo == 1" class="group__text" :style="txtStyle">
                                            <div style="margin-left:5px">Utenti</div>
                                        </div>
                                        <ul class="group__items">


                                            <a v-if="viewProfilo == 1 && isJobmule == 1" @click="goto('/initProfile')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/profilo_jobmule.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Profilo</span>
                                            </a>

                                            <a v-if="viewProfilo == 1 && isCareers == 1" @click="goto('/initProfile')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/profilo_careers.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Profilo</span>
                                            </a>


                                        </ul>
                                    </div>

                                    <div class="sidemenu__group-container">
                                        <div class="group__text" :style="txtStyle">
                                            <div style="margin-left:5px">Applicazione</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="viewHome == 1" @click="logout()" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain src="@/assets/home2.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />


                                                <span class="subitem__text">Portale</span>
                                            </a>


                                            <a v-if="viewHomeJobmule == 1" @click="logout()" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain src="@/assets/home_jobmule.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />


                                                <span class="subitem__text">Portale</span>
                                            </a>

                                        </ul>
                                    </div>


                                </div>

                                <!-- END -->

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>
</template>

<script>
// import $ from 'jquery';
import _ from 'lodash';

export default {
    name: "menubottom",
    props: {},

    mounted: function () {

        this.setUrlDomainGlobal();

        if (window.urlSiteCareers == "https://jobmule.eu/") {

            this.txtStyle = "border-color: #01adff;";
            this.isJobmule = 1;

        }
        else {

            this.txtStyle = "border-color: #14259b;";
            this.isCareers = 1;

        }


        this.setGlobal();

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");
        var user_models = window.$cookies.get("user_models");
        this.isCustSupp = window.$cookies.get("is_cust_supp");

        console.log("USER MODELS: ", user_models);

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }


        if (window.urlSiteCareers == "https://jobmule.eu/") {


            if (this.isSU == 0) {

                this.viewSlider = this.getEnableConf("slider_jobmule");
                this.viewMailGruppi = this.getEnableConf("gruppi_mail_jobmule");
                this.viewMailInvio = this.getEnableConf("invio_jobmule");
                this.viewAziende = this.getEnableConf("aziende_jobmule");
                this.viewCategorie = this.getEnableConf("categorie_jobmule");
                this.viewGiornataLavoroTipo = this.getEnableConf("giorn_lav_tipo_jobmule");
                this.viewPrivacy = this.getEnableConf("provacy_jobmule");
                this.viewSettore = this.getEnableConf("settore_jobmule");
                this.viewSkills = this.getEnableConf("skills_jobmule");
                this.viewSottoCategorie = this.getEnableConf("subcat_jobmule");
                this.viewStudi = this.getEnableConf("studi_jobmule");
                this.viewTipoContratto = this.getEnableConf("tipo_contr_jobmule");
                this.viewProfilo = this.getEnableConf("profilo_jobmule");
                this.viewComuni = this.getEnableConf("comuni_jobmule");
                this.viewProvince = this.getEnableConf("province_jobmule");
                this.viewRegioni = this.getEnableConf("regioni_jobmule");
                this.viewStatiIter = this.getEnableConf("stati_iter_jobmule");
                this.viewAnnunci = this.getEnableConf("annunci_jobmule");
                this.viewCandidati = this.getEnableConf("candidati_jobmule");
                this.viewCVRicevuti = this.getEnableConf("cv_ric_jobmule");
                this.viewReferenti = this.getEnableConf("refer_jobmule");
                this.viewRicerca = this.getEnableConf("ricerca_jobmule");
                this.viewLogoPortale = this.getEnableConf("logo_jobmule");
                this.viewImmaginiSfondoPortale = this.getEnableConf("imgs_sofndo_jobmule");
                this.viewAziendaPortale = this.getEnableConf("azienda_jobmule");
                this.viewRSSPortale = this.getEnableConf("rss_jobmule");
                this.viewPoweredBy = this.getEnableConf("power_by_jobmule");
                this.viewPrivacyPortale = this.getEnableConf("privacy_portale_jobmule");
                this.viewTermOfUse = this.getEnableConf("term_of_use_jobmule");
                this.viewCVCandidato = this.getEnableConf("cv_personali");
                this.viewCatSkills = this.getEnableConf("cat_skills_jobmule");
                this.viewGruppi = this.getEnableConf("gruppi_jobmule");
                this.viewNewsletter = this.getEnableConf("newsletter_jobmule");
                this.viewInvioNewsletter = this.getEnableConf("invio_newsletter_jobmul");
                this.viewHomeJobmule = 1;


            }
            else {


                this.viewSlider = 1;
                this.viewMailGruppi = 1;
                this.viewMailInvio = 1;
                this.viewAziende = 1;
                this.viewCategorie = 1;
                this.viewGiornataLavoroTipo = 1;
                this.viewPrivacy = 1;
                this.viewSettore = 1;
                this.viewSkills = 1;
                this.viewSottoCategorie = 1;
                this.viewStudi = 1;
                this.viewTipoContratto = 1;
                this.viewProfilo = 1;
                this.viewComuni = 1;
                this.viewProvince = 1;
                this.viewRegioni = 1;
                this.viewStatiIter = 1;
                this.viewAnnunci = 1;
                this.viewCandidati = 1;
                this.viewCVRicevuti = 1;
                this.viewReferenti = 1;
                this.viewRicerca = 1;
                this.viewCVCandidato = 1;
                this.viewLogoPortaleJobmule = 1;
                this.viewImmaginiSfondoPortaleJobmule = 1;
                this.viewAziendaPortaleJobmule = 1;
                this.viewRSSPortaleJobmule = 1;
                this.viewPoweredByJobmule = 1;
                this.viewPrivacyPortaleJobmule = 1;
                this.viewTermOfUseJobmule = 1;
                this.viewHomeJobmule = 1;
                this.viewCatSkills = 1;
                this.viewGruppi = 1;
                this.viewNewsletter = 1;
                this.viewInvioNewsletter = 1;
                this.viewPrivacyHomePortaleJobmule = 1;



            }





        }
        else {

            if (this.isSU == 1) {

                this.viewLogoPortale = 1;
                this.viewImmaginiSfondoPortale = 1;
                this.viewAziendaPortale = 1;
                this.viewRSSPortale = 1;
                this.viewPoweredBy = 1;
                this.viewPrivacyPortale = 1;
                this.viewPrivacyHomePortale = 1;
                this.viewTermOfUse = 1;
                this.viewGruppi = 1;
                this.viewNewsletter = 1;
                this.viewInvioNewsletter = 1;
                this.viewHome = 1;
                this.viewProfilo = 1;

            }
            else {

                this.viewSlider = this.getEnableConf("slider_jobmule");
                this.viewMailGruppi = this.getEnableConf("gruppi_mail_jobmule");
                this.viewMailInvio = this.getEnableConf("invio_jobmule");
                this.viewAziende = this.getEnableConf("aziende_jobmule");
                this.viewCategorie = this.getEnableConf("categorie_jobmule");
                this.viewGiornataLavoroTipo = this.getEnableConf("giorn_lav_tipo_jobmule");
                this.viewPrivacy = this.getEnableConf("provacy_jobmule");
                this.viewSettore = this.getEnableConf("settore_jobmule");
                this.viewSkills = this.getEnableConf("skills_jobmule");
                this.viewSottoCategorie = this.getEnableConf("subcat_jobmule");
                this.viewStudi = this.getEnableConf("studi_jobmule");
                this.viewTipoContratto = this.getEnableConf("tipo_contr_jobmule");
                this.viewProfilo = this.getEnableConf("profilo_jobmule");
                this.viewComuni = this.getEnableConf("comuni_jobmule");
                this.viewProvince = this.getEnableConf("province_jobmule");
                this.viewRegioni = this.getEnableConf("regioni_jobmule");
                this.viewStatiIter = this.getEnableConf("stati_iter_jobmule");
                this.viewAnnunci = this.getEnableConf("annunci_jobmule");
                this.viewCandidati = this.getEnableConf("candidati_jobmule");
                this.viewCVRicevuti = this.getEnableConf("cv_ric_jobmule");
                this.viewReferenti = this.getEnableConf("refer_jobmule");
                this.viewRicerca = this.getEnableConf("ricerca_jobmule");
                this.viewLogoPortale = this.getEnableConf("logo_jobmule");
                this.viewImmaginiSfondoPortale = this.getEnableConf("imgs_sofndo_jobmule");
                this.viewAziendaPortale = this.getEnableConf("azienda_jobmule");
                this.viewRSSPortale = this.getEnableConf("rss_jobmule");
                this.viewPoweredBy = this.getEnableConf("power_by_jobmule");
                this.viewPrivacyPortale = this.getEnableConf("privacy_portale_jobmule");
                this.viewTermOfUse = this.getEnableConf("term_of_use_jobmule");
                this.viewCVCandidato = this.getEnableConf("cv_personali");
                this.viewCatSkills = this.getEnableConf("cat_skills_jobmule");
                this.viewNewsletter = this.getEnableConf("newsletter_jobmule");
                this.viewInvioNewsletter = this.getEnableConf("invio_newsletter_jobmul");
                this.viewHome = 1;

            }


        }



    },
    data() {

        return {

            txtStyle: "",

            isJobmule: 0,
            isCareers: 0,

            viewHome: 0,
            viewHomeJobmule: 0,

            viewSlider: 0,
            viewMailGruppi: 0,
            viewMailInvio: 0,
            viewAziende: 0,
            viewCategorie: 0,
            viewGiornataLavoroTipo: 0,
            viewPrivacy: 0,
            viewSettore: 0,
            viewSkills: 0,
            viewSottoCategorie: 0,
            viewStudi: 0,
            viewTipoContratto: 0,
            viewProfilo: 0,
            viewComuni: 0,
            viewProvince: 0,
            viewRegioni: 0,
            viewStatiIter: 0,
            viewAnnunci: 0,
            viewCandidati: 0,
            viewCVRicevuti: 0,
            viewReferenti: 0,
            viewRicerca: 0,
            viewLogoPortale: 0,
            viewImmaginiSfondoPortale: 0,
            viewAziendaPortale: 0,
            viewRSSPortale: 0,
            viewPoweredBy: 0,
            viewPrivacyPortale: 0,
            viewTermOfUse: 0,
            viewLogoPortaleJobmule: 0,
            viewImmaginiSfondoPortaleJobmule: 0,
            viewAziendaPortaleJobmule: 0,
            viewRSSPortaleJobmule: 0,
            viewPoweredByJobmule: 0,
            viewPrivacyPortaleJobmule: 0,
            viewTermOfUseJobmule: 0,
            viewCVCandidato: 0,
            viewCatSkills: 0,
            viewGruppi: 0,
            viewNewsletter: 0,
            viewInvioNewsletter: 0,
            viewPrivacyHomePortale: 0,
            viewPrivacyHomePortaleJobmule: 0,



            isAdmin: 0,
            isSU: 0,
            isCustSupp: 0,
            sheetAziende: false,
            currentFile: null,
            selecting: 0,
            currItem: {},
            currGruppo: {},
            switch1: true,
            nomeCorrente: "",
            titolo: "",
            body: "",
            aziende: [],
            gruppi: [],
            globalArrAziende: [],
            dipendenti: [],
            search: '',
            sheetGruppi: false,
            sheetEmpty: false,
            sheetDipendenti: false,
            searchDipendenti: '',
            searchGruppi: '',

        };
    },

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },
    created() {
        this.intervalHandle = setInterval(this.tick, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalHandle);
    },
    methods: {


        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            console.log("VALORE PERMISSION: ", v_product_permissions);

            if (v_product_permissions != null) {

                var elements = v_product_permissions.split("|");

                for (var x = 0; x <= elements.length - 1; x++) {

                    if (elements[x] == v_codice) {

                        result = 1;

                    }


                }

            }



            return result;

        },


        setUrlDomainGlobal: function () {

            let result = "";

            if (window.siteForTest == "") {

                if (location.hostname == "localhost") {
                    result = location.protocol + "//emtool.local/";
                }

                if (location.hostname != "localhost") {

                    result = location.protocol + "//" + location.hostname + "/";

                }

            }
            else {

                result = window.siteForTest;

            }



            window.urlSiteCareers = result;

        },



        logout: function () {
            this.sheetAziende = false;
            this.$root.$children[0].logout();
        },

        gotoOld: function (link) {

            this.sheetAziende = false;
            return this.$router.push(link);
            //  this.$router.push('/profile')

        },

        goto: function (link) {

            this.sheetAziende = false;

            window.$cookies.set("page_reload", link, "9y");


            return this.$router.push("/reloadPageMenu");

        },



        setGlobal: function () {

            this.aziende = this.$root.$children[0].aziende;
            this.dipendenti = this.$root.$children[0].dipendenti;
            this.gruppi = this.$root.$children[0].gruppi;
            this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        },

        openmenu: function () {

            this.setGlobal();

            this.sheetAziende = true;
        },
        manageClose: function () {

            // this.setupButtons();
            this.sheetAziende = false;
            // this.sheetEmpty = false;
            this.sheetDipendenti = false;
            // this.sheetGruppi = false;

            this.$root.$children[0].bnavbuttons = this.$root.$children[0].old_bnavbuttons;

        },

    }
};
</script>

<style scoped>
/****** MENU ******/
.menu-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.menu__item .title,
.menu-icon .title {
    display: block;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .05em;
}

.menu__settings-icon {
    height: 26px !important;
    width: 26px !important;
    margin-bottom: 4px !important;
    margin-top: 2px !important;
}

#sidemenu {

    z-index: 2;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    position: fixed;
    top: 54px;
    left: 0;
    background: transparent;
    bottom: 56px;
    padding: 28px 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

#sidemenu.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

#sidemenu .sidemenu__inner-wrapper {
    overflow: hidden;
    background: white;
    height: 100%;
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
}

.sidemenu__top-level-item {
    width: 83px;
    text-align: center;
    border-bottom: 1px solid #efefef;
    border-right: 1px solid #efefef;
    opacity: 1;
}

sidemenu__top-level-item a {
    width: 80px;
}

.sidemenu__top-level-item>a {
    height: 80px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
}

.sidemenu__top-level-item>a:before {
    position: absolute;
    top: 0;
    left: -3px;
    height: 100%;
    width: 3px;
    z-index: 1;
    content: '';
    display: block;
}

.sidemenu__top-level-item>a:hover {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    opacity: .7;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    display: block;
    margin-bottom: 4px;
    width: 30px;
}

.sidemenu__top-level-item:hover {
    background-color: #f9f9f9;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__icon {
    -webkit-filter: none;
    filter: none;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__text {
    opacity: 1;
}

.sidemenu__sub-items-container .group__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -1px;
    position: relative;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem,
.sidemenu__top-level-item>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    width: 84px
        /* 84px */
    ;
    height: 80px;
    position: relative;
    margin-right: -1px;
    margin-bottom: -1px;
    cursor: pointer;
}

.sidemenu__subitem {
    width: 120px !important;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem:hover {
    background: #f9f9f9;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem img,
.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    width: 28px;
    margin-bottom: 20px;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    margin-bottom: 20px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem .subitem__text,
.sidemenu__top-level-item .sidemenu__top-level-item__text {
    position: absolute;
    top: 48px;
    max-width: 80px;
    font-size: 9.5px;
    line-height: 1.05em;
    font-weight: 500;
    color: #404040;
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    top: 50px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    line-height: 1.1em;
    font-weight: 600;
    font-size: 9px;
}

.sidemenu__sub-items-container .group__text {
    background-color: #f9f9f9;
    font-weight: 700;
    padding: 20px 16px 6px 16px;
    padding: 1px;

    font-size: 12px;
    color: rgba(0, 0, 0, 0.78);
    letter-spacing: 0px;
    border-bottom: 2px solid #f3f3f3;
}

.sidemenu__sub-items-container.hidden {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.sidemenu__sub-items-container {
    z-index: 1;

    position: absolute;
    position: fixed;
    background: white;
    overflow-y: auto;
    width: 100vw;
    bottom: 0px;
    /*border: 1px black solid;*/
    height: 100vh;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-content: inherit;

    /* justify-content: flex-end; */
    justify-content: start;

    bottom: 56px;

    max-height: calc(100vh - 120px);


}

.sidemenu__group-container ul {
    padding-left: 0px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container img {
    max-width: 26px;
}

.v-image__image.v-image__image--contain {
    transform: translateY(-6px);
}

.sidemenu__group-container .v-image.v-responsive.theme--light {
    margin-bottom: 22px;
}

@media screen and (max-width: 768px) {

    .sidemenu__sub-items-container {
        justify-content: end;
        display: flex;
        max-height: calc(100vh - 120px);
    }
}
</style>
