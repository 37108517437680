<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center"
        style="max-width: 300px; min-width: 300px">

        <div class="Absolute-Center">

        </div>

    </v-container>
</template>

<script>
import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';
//import Login from './login.vue'

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];
        bus.$off("bottomIconClickEvent");
        this.$root.$children[0].showProgress = false;
    },

    mounted: async function () {


        setTimeout(() => {

            router.push({
                path: "/editGroupMails"
            });

        }, 100);



    }


};
</script>
