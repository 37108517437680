import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIReferenti {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async initInsertReferente(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("TwoRecruitRefsInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertReferente(referente, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in referente) {
            formData.append(key, referente[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitRefsInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async initEditReferente(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitRefsInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateReferente(referente, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        for (var key in referente) {
            formData.append(key, referente[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitRefsUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteReferenti(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitRefsDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getFilter(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("getFiltRef"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async exportXLS(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("TwoRecruitRefsExportXlsAllApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

}

export default new APIReferenti({
    url: "https://services.ebadge.it/public/api/"
})
