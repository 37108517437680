import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIProfileCandidate {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async getProfileCandidate(user, portale) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("portale", portale);
   
        return axios.post(this.getUrlDomain("JMGetCandProfile"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async setProfileCandidate(profile, user, portale) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("portale", portale);
   
        for (var key in profile) {
            formData.append(key, profile[key]);
        }
   
        return axios.post(this.getUrlDomain("JMSetCandProfile"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async mailDeleteProfileCandidate(user, email, portale, v_url) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("email", email);
        formData.append("portale", portale);
        formData.append("v_url", v_url);
   
        return axios.post(this.getUrlDomain("JMMailDelCandProfile"), formData, { 'Content-Type': 'multipart/form-data' });

    }


}

export default new APIProfileCandidate ({
    url: "https://services.ebadge.it/public/api/"
})
