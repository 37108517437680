<template>
  <div id="regCompany">



    <link href="https://fonts.googleapis.com/css?family=Roboto:100,400,800" rel="stylesheet" />


    <!--  <h1>Login</h1>
          <input type="text" name="username" v-model="input.username" placeholder="Username" />
          <input type="password" name="password" v-model="input.password" placeholder="Password" />
          <button type="button" v-on:click="login()">Login</button> -->

    <div class="fs-container">
      <div class="fs-container__bg"></div>
      <div class="content">
        <div class="content-login">
          <div class="content__pane init2">
            <img class="logo" src="@/assets/jobmule-logo-small.png" style="max-width: 100px" />

            <form name="login" id="login-form" novalidate="" method="POST" v-on:submit.prevent style="max-width: 286px;">
              <div class="flex-container">
                <input type="hidden" id="t_login" name="t_login" value="NO_QRCODE" />

                <div id="classic-login">

                  <div class="group filled">


                    <input v-model="input.regione_sociale" id="regione_sociale" class="regione_sociale" required=""
                      placeholder="Business Name" name="regione_sociale" type="text" value="" />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden">Username</span>
                    </label>
                  </div>


                  <div class="group filled">
                    <input v-model="input.email" id="mail" class="mail" required="" placeholder="E-Mail" name="mail"
                      type="email" value="" />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden">Surname</span>
                    </label>
                  </div>



                  <div class="group filled">
                    <input v-model="input.sede_legale" id="sede_legale" class="sede_legale" required=""
                      placeholder="Legal Office" name="sede_legale" type="text" value="" />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden">Surname</span>
                    </label>
                  </div>

                  <div class="group filled">
                    <input v-model="input.citta" id="citta" class="citta" required="" placeholder="City" name="citta"
                      type="text" value="" />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden">Surname</span>
                    </label>
                  </div>


                  <div class="group filled">
                    <input v-model="input.cap" id="cap" class="cap" required="" placeholder="ZIP Code" name="cap" type="text"
                      value="" />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden">Surname</span>
                    </label>
                  </div>






                  <div class="group filled">
                    <input v-model="input.partita_iva" id="partita_iva" class="partita_iva" required=""
                      placeholder="VAT Number" name="text" type="text" value="" />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden">Phone</span>
                    </label>
                  </div>


                  <div class="group filled">


                    <input v-model="input.sdi" id="sdi" class="sdi" required="" name="sdi" type="text" placeholder="SDI"
                      value="" />

                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <img src="@/assets/loginstart/img/user.png" />
                      <span class="label__text label__text--hidden">Username</span>
                    </label>
                  </div>

                  <div class="group filled" style="margin-bottom: 0px">
                    <input v-model="input.pec" id="pec" class="pec" placeholder="Pec" required="" name="pec" type="email"
                      value="" />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>
                      <span class="label__text label__text--hidden">Password</span>
                      <img src="@/assets/loginstart/img/user.png" />
                    </label>

                    <!--   <a href=" " id="forgotPass" :to="register">
                        Sign up</a> -->
                    <a id="forgotPass">

                      <!-- <router-link id="forgotPass" tag="tr" :to="'/'"> Login</router-link> -->

                    </a>


                  </div>

                  <!-- <div class="group filled" style="margin-bottom: 0px">

                    <span class="files">
                      <label style="margin-left:11px!important;font-size:11px;color:black;font-weight: bold;">Logo</label>
                      <input type="file" id="input-file-logo" name="logo" accept=".png" class="dropify"
                        data-max-width="6610" data-max-height="6200" />
                    </span>



                  </div> -->



                </div>

                <div id="qr-code-wrapper">
                  <button type="button" id="qr-code-btn">
                    <span class="tooltiptext">Login with QR-Code</span>

                    <img src="@/assets/loginstart/img/qr-btn-alpha.png" alt="submit" />
                  </button>
                </div>

                <!-- added code -->

                <div class="container" id="qr-login" style="display: none">
                  <div>
                    <a class="button" id="resetButton">Back</a>
                  </div>

                  <div>
                    <video id="video" width="300" height="200" style="border: 1px solid gray"></video>
                  </div>

                  <div id="sourceSelectPanel" style="display: none">
                    <label for="sourceSelect">Change video source:</label>
                    <select id="sourceSelect" style="max-width: 400px"></select>
                  </div>

                  <div style="text-align: left; display: none">
                    <label>Result:</label>
                    <pre><code id="result"></code></pre>
                  </div>
                </div>

                <!-- end added code -->
              </div>

              <div id="lang-select" class="group select">
                <div class="lang__field">
                  <span class="lang__selected"><img src="@/assets/loginstart/img/uk.png" class="flag-img" /></span>
                  <label class="select__label">
                    <img src="@/assets/loginstart/img/language.png" />
                  </label>
                  <div class="lang__options">
                    <div data-value="EN" class="lang__option">
                      <img src="@/assets/loginstart/img/uk.png" class="flag-img" />
                    </div>
                    <div data-value="IT" class="lang__option">
                      <img src="@/assets/loginstart/img/italy.png" class="flag-img" />
                    </div>
                  </div>
                </div>
                <span class="highlight"></span>
                <span class="bar"></span>
              </div>

              <div id="otp-select" class="group select">
                <div class="otp__field">
                  <span class="otp__selected">
                    <div class="otp-text">Email</div>
                    <img src="@/assets/loginstart/img/mail.png" class="otp-img" />
                  </span>
                  <label class="select__label">
                    <div class="text-label">OTP</div>
                    <img src="@/assets/loginstart/img/token.png" />
                  </label>
                  <div class="otp__options">
                    <div data-value="MAIL" class="otp__option">
                      <div class="otp-text">Email</div>
                      <img src="@/assets/loginstart/img/mail.png" class="otp-img" />
                    </div>
                    <div data-value="SMS" class="otp__option">
                      <div class="otp-text">Sms</div>
                      <img src="@/assets/loginstart/img/sms.png" class="otp-img" />
                    </div>

                    <div data-value="Auth" class="otp__option">
                      <div class="otp-text">Auth</div>
                      <img src="@/assets/loginstart/img/auth.png" class="otp-img" />
                    </div>
                  </div>
                </div>
                <span class="highlight"></span>
                <span class="bar"></span>
              </div>

              <button style="margin-top:25px" class="
                    button button--block button--outline button--primary
                    modal-trigger
                  " id="login-button" value="login" v-on:click="register()">
                <div class="button--text">Sign up</div>
                <div class="spinner hidden">
                  <div class="holder">
                    <div class="preloader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </button>

              <img src="@/assets/back-jobmule.png" class="clBtnBack" id="btnBackCareers" title="Back" @click="goBack()" />

              <rise-loader v-if="showSpinner" :size="'20px'" :color="'#01adff'" />
              <!-- <div class="info info--error info--hidden">
                  Devi inserire sia il nome utente sia la password
                </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>




    <div class="contpopupotpregister" style="display:none">

      <template>
        <modal name="popupOtpRegister" :clickToClose="false" :width="262" :height="215">


          <v-row>

            <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

              Codice OTP inviatoLe via mail

            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12" md="12">

              <v-text-field type="password" v-model="valueOtpRegister" label="" id="fldValueOtpRegister">
              </v-text-field>

            </v-col>

          </v-row>

          <v-row v-if="showResendOtpRegister">

            <v-col cols="12" md="12" style="display: flex;
                        justify-content: center;
                        padding-top: 0px!important;
                        margin-top: 0px!important;">

              <div>OTP non ricevuto ? </div>
              <div style="font-weight: bold;
                        margin-left: 10px;
                        cursor: pointer;" class="clReinviaOtpRegister">Reinvia</div>

            </v-col>

          </v-row>


          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">

                <v-img alt="" class="btn-cancel-otp-register" contain src="@/assets/btn_cancel3_jobmule.png"
                  max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                  transition="scale-transition" title="Annulla" @click="btnCancelOtpRegister" />

                <v-img alt="" class="btn-confirm-otp-register" contain src="@/assets/btn_confirm3_jobmule.png"
                  max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" title="Conferma"
                  @click="btnConfirmOtpRegister" />


              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>







  </div>
</template>
  
  
  
  
  
  
  
  
<style scoped lang="css">
@import "./../assets/loginstart/style.css";
</style>
  
<script>
//@import "@/assets/loginstart/style.css"
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import RiseLoader from "vue-spinner/src/RiseLoader.vue";
import router from ".././router";
import $ from 'jquery';
import apiotp from "../utils/otp/apiotp";

/* import Vue from 'vue' */
//import App from '.././App.vue'

export default {
  components: {
    /* PulseLoader, */
    RiseLoader,
  },

  updated() { },

  mountedOLD: function () {
    console.log(this.$route.path);
    if (this.$route.path == "/") {
      // console.log( this.$parent );
      //  window.App.ok = 1 ;
      //   App.hideToolbars = true ;
      // Vue.set(this.$root , 'hideToolbars', true)
    } else {
      // window.App.ok = 0 ;
      //  App.hideToolbars = false ;
      // this.$root.hideToolbars = false ;
      // Vue.set(this.$root , 'hideToolbars', false)
    }
  },
  mounted: function () {

    this.setUrlDomainGlobal();



    var loggedIn = this.$cookies.get('token');

    if (loggedIn) {
      router.push({ path: "/dash" });
    }
    console.log("Ciao: " + this.userLogged);

    window.sendOtpRegister = 1;

    $(document).ready(function () {


      var oggDrop = $('.dropify').dropify({
        messages: {
          default: 'Drag',
          replace: /* s */ '',
          remove: 'Rimuovi',
          error: 'File troppo grande'
        }
      });

      $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
      $(".dropify-render").append("<img>");

      oggDrop.on('dropify.afterClear', function (event, element) {

        console.log(event, element);


      });



    });



    //        this.$cookie.get('jwt_token')
    //console.log ( this.$cookie.getAll() );
    //$cookie.set('cookie_name', 'cookie_value');
    //window.$cookie.set('test', 'Hello world!', 1);
    //  console.log (window.$cookies.getCookie()  );
    // const username = this.$cookies.get("username");
    //console.log(username);
    //        this.$cookie.getAll()
  },
  name: "Login",

  // data:

  data() {
    return {

      showResendOtpRegister: true,

      valueOtpRegister: "",




      dialogRegister: true,

      notifications: false,
      sound: true,
      widgets: false,

      showSpinner: false,
      input: {
        regione_sociale: "",
        email: "",
        sede_legale: "",
        partita_iva: "",
        sdi: "",
        pec: "",
        citta: "",
        cap: ""
      },
    };
  },
  methods: {

    btnCancelOtpRegister: function () {

      this.$modal.hide('popupOtpRegister');


    },

    btnConfirmOtpRegister: function () {

      if (this.valueOtpRegister != "") {

        console.log("OTP INP: ", this.valueOtpRegister);
        console.log("ENC OTP INP: ", btoa(this.valueOtpRegister));

        if (window.myVal == btoa(this.valueOtpRegister)) {

          this.$modal.hide('popupOtpRegister');


          this.registerAction();


        }
        else {

          this.$swal({
            icon: "error",
            text: "Valore OTP non corretto",
            showConfirmButton: false,
            timer: 2000
          });


        }



      }
      else {

        this.$swal({
          icon: "error",
          text: "Specificare un  valore dell'OTP",
          showConfirmButton: false,
          timer: 2000
        });

      }



    },

    setUrlDomainGlobal: function () {

      let result = "";

      if (window.siteForTest == "") {

        if (location.hostname == "localhost") {
          result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

          result = location.protocol + "//" + location.hostname + "/";

        }

      }
      else {

        result = window.siteForTest;

      }



      window.urlSiteCareers = result;

    },


    getOtpRegister: async function () {

      var that = this;


      window.myVal = "";


      if (window.sendOtp == 1) {

        window.sendOtp = 0;



        that.showSpinner = true;
        // that.$root.$children[0].showProgress = true;


        var v_company_id = 7;

        if (window.urlSiteCareers == "https://careers.ariall.eu/") {
          v_company_id = 8;
        }



        var response = await apiotp.generateOTPAndSendMailLogin(
          that.input.email,
          'jobmule',
          'register_company',
          v_company_id
        ).then((res) => {

          that.showSpinner = false;
          //  that.$root.$children[0].showProgress = false;
          console.log("res from generateOTPAndSendMailLogin", res);

          window.myVal = res.data.Result;


          setTimeout(() => {

            that.showResendOtpRegister = true;

            that.valueOtpRegister = "";

            $(".contpopupotpregister").show();

            this.$modal.show('popupOtpRegister');

            setTimeout(() => {

              $("#fldValueOtpRegister").focus();

            }, 300);


          }, 200);





        }).catch(err => {
          that.showSpinner = false;
          // that.$root.$children[0].showProgress = false;
          console.log(err);
          var msg = err.response.data.Error;

          that.$swal({
            icon: "error",
            text: msg,
            showConfirmButton: false,
            timer: 8000
          });
          console.log("Errori", "Non è stato possibile eliminare i dati");
          console.log("response", response);

        }

        );



      }









    },





    validateEMail: function (v_email) {

      var result;

      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      console.log("MAIL: ", v_email);

      if (v_email != undefined) {

        if (v_email != "") {

          if (v_email.match(validRegex)) {
            result = true;
          }
          else {
            result = false;

          }


        }
        else {

          result = true;

        }


      }
      else {
        result = true;

      }


      return result;

    },


    goBack: function () {

      console.log("GO BACK");

      // var v_page = window.$cookies.get("page_go_back");

      // console.log("PAGE CALL: ", v_page);

      router.push({ path: '/loginAziendaJobmule' }).catch(() => { });


    },



    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
    ,
    getCookie() {
      this.$cookies.set("cookie-consent-performance", "yes");

      //  this.$swal('You are logged in!!!');

      //   console.log(Vue.cookie.get('token'));

      // it gets the cookie called `username`
      /* const username = this.$cookies.get("username");
      console.log(username); */
    },

    getUrlDomain(nome_metodo) {

      let result = "";

      if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
      }

      if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

      }

      return result + nome_metodo;


    },

    registerAction: async function () {
      //const auth = { user: this.username, password: this.password };
      // Correct username is 'foo' and password is 'bar'
      //const url = "https://app.emtool.eu/api/emt/register";

      //const url = "https://app.emtool.eu/registerCompanyJobmule";

      const url = this.getUrlDomain("registerCompanyJobmule");

      //const url = "http://emtool.local/registerUserForCompanyPost";

      this.showSpinner = true;

      this.success = false;
      this.error = null;

      let formData = new FormData();


      if (this.input.regione_sociale != undefined)
        formData.append("regione_sociale", this.input.regione_sociale);

      if (this.input.email != undefined)
        formData.append("email", this.input.email);

      if (this.input.sede_legale != undefined)
        formData.append("sede_legale", this.input.sede_legale);

      if (this.input.partita_iva != undefined)
        formData.append("partita_iva", this.input.partita_iva);

      if (this.input.sdi != undefined)
        formData.append("sdi", this.input.sdi);

      if (this.input.pec != undefined)
        formData.append("pec", this.input.pec);

      if (this.input.citta != undefined)
        formData.append("citta", this.input.citta);

      if (this.input.cap != undefined)
        formData.append("cap", this.input.cap);




      try {
        await this.axios
          .post(url, formData, { "content-type": "multipart/form-data" })
          .then((result) => {
            var respo = result.data;
            this.success = true;
            // console.log( respo   );

            setTimeout(() => {
              this.showSpinner = false;

              var msgError = "";
              console.log("re", respo.errors);



              if (respo.errors && !this.isEmpty(respo.errors)) {
                console.log("Errori", respo.errors);
                var err = respo.errors;

                Object.entries(err).forEach(([key, value]) => {
                  console.log(`${key} ${value}`);
                  msgError += `${value}\r\n `;
                });

                if (msgError) {
                  this.$swal({ icon: "error", text: msgError });

                }


              }
              else {

                // this.$swal({
                //   icon: "success",
                //   text: "Please check your email",
                //   confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_jobmule.png' />",
                // });

                // this.$router.push({
                //   path: "/",
                // });

                this.$swal({
                  icon: "success",
                  text: respo.msg_res,
                  showConfirmButton: false,
                  timer: 4000
                });


                setTimeout(() => {

                  this.$router.push({
                    path: "/searchAds",
                  });


                }, 4000);


              }


            }, 1);
          });
      } catch (err) {


        console.log("ERRORE: ", err);


        this.success = false;
        console.log(err.message);
        this.$swal({ icon: "error", text: "Please check your data" });

        this.showSpinner = false;
        //  console.log(   response);

        //   this.error = err.message;
      }
    },

    registerActionOld: async function () {
      //const auth = { user: this.username, password: this.password };
      // Correct username is 'foo' and password is 'bar'
      //const url = "https://app.emtool.eu/api/emt/register";

      const url = "https://app.emtool.eu/registerUserForCompanyPost";

      //const url = "http://emtool.local/registerUserForCompanyPost";


      this.success = false;
      this.error = null;

      let formData = new FormData();

      formData.append("context", "2recruit");

      if (this.input.username != undefined)
        formData.append("login", this.input.username);

      if (this.input.password != undefined)
        formData.append("password", this.input.password);

      formData.append("companyname", "7");

      if (this.input.name != undefined)
        formData.append("name", this.input.name);

      if (this.input.surname != undefined)
        formData.append("surname", this.input.surname);

      if (this.input.email != undefined)
        formData.append("email", this.input.email);

      if (this.input.phone != undefined)
        formData.append("phone", this.input.phone);

      // if (this.input.namecompany != undefined)
      //   formData.append("namecompany", this.input.namecompany);

      formData.append("app", "2recruit");

      formData.append("is_demo", "S");



      try {
        await this.axios
          .post(url, formData, { "content-type": "text/json" })
          .then((result) => {
            var respo = result.data;
            this.success = true;
            // console.log( respo   );

            setTimeout(() => {
              this.showSpinner = false;

              var msgError = "";
              console.log("re", respo.errors);



              if (respo.errors && !this.isEmpty(respo.errors)) {
                console.log("Errori", respo.errors);
                var err = respo.errors;

                Object.entries(err).forEach(([key, value]) => {
                  console.log(`${key} ${value}`);
                  msgError += `${value}\r\n `;
                });

                if (msgError) {
                  this.$swal({ icon: "error", text: msgError });

                }


              }
              else {
                this.$swal({
                  icon: "success",
                  text: "Please check your email",
                  confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_jobmule.png' />",
                });

                this.$router.push({
                  path: "/",
                });


              }
              //this.$swal("You are logged in!!!");

              /*     window.$cookies.set("token", respo.token , "9y");
                  window.$cookies.set("system", respo.system,"9y");
                  window.$cookies.set("token_system", respo.token_system,"9y");
                  window.$cookies.set("username", respo.username,"9y");
                  window.$cookies.set("user_id", respo.user_id,"9y");
                  window.$cookies.set("namesurname", respo.namesurname,"9y"); */

              this.$root.$children[0].curDipTop = respo.namesurname;

              //router.push({ path: "/" });

            }, 1);
          });
      } catch (err) {
        this.success = false;
        console.log(err.message);
        this.$swal({ icon: "error", text: "Please check your data" });

        this.showSpinner = false;
        //  console.log(   response);

        //   this.error = err.message;
      }
    },


    checkCap(v_value) {

      var result = true;

      if (v_value.length != 5) {
        result = false;
      }
      else {
        result = /\d/.test(v_value);
      }

      return result;

    },

    checkPartitaIva(v_value) {

      var result = true;

      if (v_value.length != 11) {
        result = false;
      }
      else {
        result = /\d/.test(v_value);
      }

      return result;

    },

    checkSDI(v_value) {

      var result = true;

      if (v_value.length != 5) {
        result = false;
      }


      return result;

    },




    register() {

      console.log("ok");

      var errore = "";

      if (this.input.regione_sociale == "") {

        errore = "Business name required";

      }

      if (errore == "") {

        if (this.input.email == "") {

          errore = "E-Mail required";

        }

      }


      if (errore == "") {

        if (this.input.sede_legale == "") {

          errore = "Legal office required";

        }

      }


      if (errore == "") {

        if (this.input.citta == "") {

          errore = "City required";

        }

      }


      if (errore == "") {

        if (this.input.cap == "") {

          errore = "ZIP Code required";

        }

      }

      if (errore == "") {

        if (!this.checkCap(this.input.cap)) {

          errore = "ZIP Code not valid";

        }

      }



      if (errore == "") {

        if (this.input.partita_iva == "") {

          errore = "VAT Number required";

        }

      }

      if (errore == "") {

        if (!this.checkPartitaIva(this.input.partita_iva)) {

          errore = "VAT Number not valid";

        }

      }




      if (errore == "") {

        if (this.input.sdi == "") {

          if (this.input.pec == "") {

            errore = "SDI or Pec required";


          }


        }

      }



      if (errore == "") {

        if (this.input.sdi != "") {

          if (!this.checkSDI(this.input.sdi)) {

            errore = "SDI not valid";

          }


        }

      }



      if (errore == "") {

        if (!this.validateEMail(this.input.email)) {

          errore = "E-Mail not valid";

        }

      }

      if (errore == "") {

        if (this.input.pec != "") {

          if (!this.validateEMail(this.input.pec)) {

            errore = "Pec not valid";

          }


        }


      }





      if (errore == "") {

        console.log("ok");

        //this.showSpinner = true;
        this.getCookie();


        this.getOtpRegister();

        //this.registerAction();

      }
      else {

        this.$swal({
          icon: "error",
          text: errore,
          showConfirmButton: false,
          timer: 2000
        });

      }






    },




  },
};
</script>

   
  
<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
  display: none;
}

#login {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: auto;
  /*   margin-top: 200px; */
  padding: 20px;
}

#nav,
.v-sheet {
  display: none;
}
</style>

<style>
#regCompany .fs-container .content {
  text-align: center;
  width: 100%;
  position: relative;
  margin: auto;
  margin-top: 240px;
  margin-top: 10vh;
}

#regCompany .clBtnBack {
  width: 40px;
  margin-top: 22px;
  cursor: pointer;
}
</style>
  
  
  