import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APICategorie {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async initInsert(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertCategoria(categoria, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in categoria) {
            formData.append(key, categoria[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitCategorieInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initEdit(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitCategorieInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateCategoria(categoria, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        for (var key in categoria) {
            formData.append(key, categoria[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitCategorieUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteCategoria(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitCategorieDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user, v_prod) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_prod", v_prod);


        return axios.post(this.getUrlDomain("TwoRecruitCategorieInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

}

export default new APICategorie({
    url: "https://services.ebadge.it/public/api/"
})
