import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;


function getUrlDomainGlob(nome_metodo)
{

  let result = "";

  if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
  }

  if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

  }

  return result + nome_metodo;


}

var main_domain = getUrlDomainGlob("");


class APIAssocUsersCompany {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getUtenti(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtAssocUserCmpGetUtentiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getUtenteSelezionato(user, user_sel) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("user_sel", user_sel);

    return axios.post(this.getUrlDomain("EmtAssocUserGetUserSelApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updAssocAzi(chk_sel, id_azienda, user, user_sel) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("user_sel", user_sel);
    formData.append("chk_sel", chk_sel);
    formData.append("id_azienda", id_azienda);

    return axios.post(this.getUrlDomain("EmtAssocUserUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updAssocAziAll(chk_sel, user, user_sel) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("user_sel", user_sel);
    formData.append("chk_sel", chk_sel);

    return axios.post(this.getUrlDomain("EmtAssocUserAziAllUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APIAssocUsersCompany({
  url: main_domain
})
